import { ChangeEvent, KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { SouthEast, SouthWest, Upload } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import * as fabric from 'fabric';
import { useDropzone } from 'react-dropzone';
import { useGetFabricRequest, useSaveFabricRequest, useUploadRequest } from '@/api/upload';
import { Attachment } from '@/types';

const MAX_CANVAS_SIZE = 800;

const getCanvasDimensionsForImage = (img: fabric.Image) => {
  let canvasSize = MAX_CANVAS_SIZE;
  const ih = img.height || 0;
  const iw = img.width || 0;

  if (ih < MAX_CANVAS_SIZE && iw < MAX_CANVAS_SIZE) {
    canvasSize = Math.max(ih, iw);
  }

  if (ih > iw) {
    const ratio = canvasSize / ih;
    return [iw * ratio, canvasSize];
  }
  const ratio = canvasSize / iw;
  return [canvasSize, ih * ratio];
};

function dataURItoBlob(dataURI: string): Blob {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0]!.indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]!);
  else byteString = unescape(dataURI.split(',')[1]!);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0]!.split(':')[1]!.split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export default function ImageEditor({
  image,
  onSave,
  designImages,
}: {
  image: string;
  onSave: (newUrl: string) => void;
  designImages?: Attachment[];
}) {
  const uploadRequest = useUploadRequest('product_image');
  const getFabricRequest = useGetFabricRequest(image);
  const saveFabricRequest = useSaveFabricRequest();

  const [loading, setLoading] = useState(false);
  const fabricInstance = useRef<fabric.Canvas>();

  const onKeyUp: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const canvas = fabricInstance.current as fabric.Canvas;
      const { key } = e;
      if (key === 'Backspace' || key === 'Delete') {
        e.preventDefault();
        const obj = canvas.getActiveObject();
        if (obj) {
          canvas.remove(obj);
        }
      }
    },
    [fabricInstance],
  );

  const initCanvas = useCallback(
    (prevCanvasState: string | null) => {
      setLoading(true);
      const canvas = new fabric.Canvas('c', {
        width: MAX_CANVAS_SIZE,
        height: MAX_CANVAS_SIZE,
      });
      fabricInstance.current = canvas;

      if (prevCanvasState) {
        canvas
          .loadFromJSON(prevCanvasState, (_, object) => {
            if (object) {
              // @ts-ignore
              canvas.setActiveObject(object);
            }
          })
          .then(() => {
            const { backgroundImage } = canvas;
            if (backgroundImage instanceof fabric.Image) {
              if (
                backgroundImage.height &&
                backgroundImage.scaleY &&
                backgroundImage.width &&
                backgroundImage.scaleX
              ) {
                canvas.setDimensions({
                  height: backgroundImage.height * backgroundImage.scaleY,
                  width: backgroundImage.width * backgroundImage.scaleX,
                });
              }
            }
            canvas.renderAll();
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        const imageUrl = image.includes('files.availerp.com')
          ? image
          : `https://availerp.com/cors/${encodeURIComponent(image)}`;
        fabric.Image.fromURL(imageUrl, { crossOrigin: 'anonymous' }, { left: 0, top: 0 })
          .then((img) => {
            img.set({
              left: 0,
              top: 0,
            });
            const [width, height] = getCanvasDimensionsForImage(img);
            canvas.setDimensions({
              height,
              width,
            });
            canvas.backgroundImage = img;
            canvas.backgroundImage.scaleX = canvas.width / img.width!;
            canvas.backgroundImage.scaleY = canvas.height / img.height!;
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [fabricInstance, setLoading],
  );

  useEffect(() => {
    if (getFabricRequest.status !== 'loading') {
      let prevState = getFabricRequest.data || null;
      if (!prevState) {
        prevState = localStorage.getItem(`image-editor-${image}`);
      }
      initCanvas(prevState);
    }
  }, [initCanvas, image, getFabricRequest.status]);

  const skew = (amount: number) => {
    const activeObject = fabricInstance.current?.getActiveObject();
    if (activeObject) {
      const updateTo = (activeObject.skewY || 0) + amount;
      activeObject.set('skewY', updateTo).setCoords();
      fabricInstance.current?.requestRenderAll();
    }
  };

  const addImage = useCallback(
    (url: string) => {
      const canvas = fabricInstance.current as fabric.Canvas;
      setLoading(true);
      const promise =
        url.split('?')[0]!.endsWith('.svg') || url.startsWith('data:image/svg')
          ? fabric
              .loadSVGFromURL(url, undefined, {
                crossOrigin: 'anonymous',
              })
              .then(({ objects }) => {
                // @ts-ignore
                return fabric.util.groupSVGElements(objects);
              })
          : fabric.Image.fromURL(url, { crossOrigin: 'anonymous' }, { left: 100, top: 100 });

      promise.then((img) => {
        img.set({
          left: 100,
          top: 100,
        });
        img.scaleToHeight(100);
        img.scaleToWidth(100);

        // Add to Canvas, then make it active
        canvas.add(img);
        canvas.setActiveObject(img);
        setLoading(false);
      });
    },
    [setLoading, fabricInstance],
  );
  const readFile = useCallback((file: File) => {
    const a = new FileReader();
    a.onload = (e) => {
      if (typeof e.target?.result === 'string') {
        addImage(e.target.result);
      }
    };
    a.readAsDataURL(file);
  }, []);

  const onUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        readFile(e.target.files[0]!);
      }
    },
    [readFile],
  );

  const handleSaveClick = () => {
    if (fabricInstance.current) {
      fabricInstance.current!.discardActiveObject();
      const dataURL = fabricInstance.current!.toDataURL({
        format: 'png',
        quality: 1,
        multiplier: 2,
      });
      const blob = dataURItoBlob(dataURL);
      const fabricState = JSON.stringify(fabricInstance.current!.toJSON());

      uploadRequest.mutateAsync(blob).then((data) => {
        saveFabricRequest.mutate({
          path: data.file,
          fabric: fabricState,
        });
        onSave(data.url);
      });
    }
  };

  const onDrop = useCallback(
    (files: File[]) => {
      readFile(files[0]!);
    },
    [readFile],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
    },
    noClick: true,
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onKeyUp={onKeyUp}
      style={{
        width: '100%',
        height: 'calc(100vh - 100px)',
      }}
    >
      <Box mb={4} gap={2} display="flex">
        <Button component="label" size="small" variant="outlined">
          <input type="file" onChange={onUpload} style={{ display: 'none' }} accept="image/*" />
          Upload <Upload />
        </Button>
        <Button
          onClick={() => skew(-1)}
          size="small"
          variant="outlined"
          disabled={!fabricInstance.current?.getActiveObject()}
        >
          Skew Left <SouthWest />
        </Button>
        <Button
          onClick={() => skew(1)}
          size="small"
          variant="outlined"
          disabled={!fabricInstance.current?.getActiveObject()}
        >
          Skew Right <SouthEast />
        </Button>
        <LoadingButton
          loading={uploadRequest.isLoading}
          onClick={handleSaveClick}
          size="small"
          variant="contained"
        >
          Save
        </LoadingButton>
        {loading && <CircularProgress />}
      </Box>

      <Box display="flex">
        <Box id="canvasColumn" flexGrow={1}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <canvas style={{ border: '1px solid #eee' }} id="c" />
          </div>
        </Box>
        <Box id="imagesColumn" px={2} width={250}>
          {designImages && designImages.length > 0 ? (
            <>
              <Typography variant="subtitle2" gutterBottom>
                Saved Image Files
              </Typography>
              <List>
                {designImages.map((att) => (
                  <ListItemButton key={att.url} onClick={() => addImage(att.url)}>
                    <ListItemAvatar>
                      <Avatar src={att.url} />
                      <ListItemText primary={att.name || att.file} />
                    </ListItemAvatar>
                  </ListItemButton>
                ))}
              </List>
            </>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No saved images
            </Typography>
          )}
        </Box>
      </Box>
    </div>
  );
}
