import { Box, Card, CardContent, Stack, Tab, Tabs, Typography } from '@mui/material';
import groupBy from 'lodash/groupBy';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { InventoryPickWithItems, PurchaseOrderWithItems } from '@/local-types';
import { InventoryPickItem, OrderItem, OrderItemToPurchase, PurchaseOrderItem } from '@/types';
import { abscomp } from '@/utils/helpers';
import NoteViewer from '../Notes/NoteViewer';
import InventoryPickCard from './InventoryPickCard';
import ItemsToPurchaseCard from './ItemsToPurchaseCard';
import OrderItemsForPurchasing from './OrderItemsForPurchasing';
import PurchaseOrderCard from './PurchaseOrderCard';

const hasQtyToPurchase = (i: OrderItem) => abscomp(i.qty_sourced, '<', i.qty);

const withCount = (label: string, count?: number) => {
  if (!count) {
    return label;
  }
  return `${label} (${count})`;
};

export default function PurchasingForOrder({
  orderId,
  items,
  poItems,
  pickItems,
}: {
  orderId: number;
  items: OrderItemToPurchase[];
  poItems: PurchaseOrderItem[];
  pickItems: InventoryPickItem[];
}) {
  const [tab, setTab] = useQueryParam('tab', withDefault(StringParam, 'sourced'));

  const toPurchase = groupBy(items.filter(hasQtyToPurchase), (r) =>
    [r.vendor?.id, r.ship_to?.address?.id].join('|'),
  );

  const groupedPoItems = groupBy(poItems, (r) => r.purchase_order_id);
  const groupedPickItems = groupBy(pickItems, (r) => r.inventory_pick_id);

  const purchaseOrderWithItems = Object.values(groupedPoItems).map((itemsForPo) => ({
    ...itemsForPo[0]!.purchase_order,
    items: itemsForPo,
  })) as PurchaseOrderWithItems[];

  const pickWithItems = Object.values(groupedPickItems).map((itemsForPick) => ({
    ...itemsForPick[0]!.pick,
    items: itemsForPick,
  })) as InventoryPickWithItems[];

  const otherRows = purchaseOrderWithItems
    .map((po) => ({
      label: po.label,
      href: `/purchase-orders/${po.id}`,
      key: po.id,
    }))
    .concat(
      pickWithItems.map((pick) => ({
        label: pick.number,
        href: `/inventory-picks/${pick.id}`,
        key: pick.id,
      })),
    );

  const issuedPicks = pickWithItems.filter((i) => i.status === 'issued');

  return (
    <>
      <NoteViewer resource="orders" resourceId={orderId} tag="purchasing" />

      {Object.keys(toPurchase).length === 0 && (
        <Typography variant="body1">There are no items to purchase for this order.</Typography>
      )}

      <Stack spacing={2}>
        {Object.entries(toPurchase).map(([group, groupItems]) => (
          <ItemsToPurchaseCard
            key={group}
            orderId={orderId}
            items={groupItems}
            issuedPick={issuedPicks.find(
              (i) => i.address?.name === groupItems[0].ship_to.address?.name,
            )}
          />
        ))}
      </Stack>

      <Box mt={2}>
        <Tabs value={tab} onChange={(e, t) => setTab(t)}>
          <Tab
            value="sourced"
            label={withCount('POs + Picks', purchaseOrderWithItems.length + pickWithItems.length)}
          />
          <Tab value="all" label={withCount('All Items', items.length)} />
        </Tabs>

        {tab === 'sourced' && (
          <Stack spacing={2}>
            {purchaseOrderWithItems.map((po) => (
              <PurchaseOrderCard key={po.id} po={po} otherRows={otherRows} />
            ))}
            {pickWithItems.map((pick) => (
              <InventoryPickCard key={pick.id} pick={pick} otherRows={otherRows} />
            ))}
            {purchaseOrderWithItems.length === 0 && pickWithItems.length === 0 && (
              <Typography variant="body1" color="textSecondary" sx={{ py: 2 }}>
                No items have been purchased or pulled.
              </Typography>
            )}
          </Stack>
        )}

        {tab === 'all' && (
          <Card>
            <OrderItemsForPurchasing items={items} />
            {items.length === 0 && (
              <CardContent>
                <Typography variant="body1" color="textSecondary">
                  There are no purchasable items for this order.
                </Typography>
              </CardContent>
            )}
          </Card>
        )}
      </Box>
    </>
  );
}
