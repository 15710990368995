import { z } from 'zod';

export const accountTypeSchema = z.union([
  z.literal('asset'),
  z.literal('bank'),
  z.literal('long_term_asset'),
  z.literal('other_asset'),
  z.literal('other_current_asset'),
  z.literal('liability'),
  z.literal('credit_card'),
  z.literal('tax'),
  z.literal('long_term_liability'),
  z.literal('other_current_liability'),
  z.literal('equity'),
  z.literal('revenue'),
  z.literal('expense'),
  z.literal('cogs'),
]);

export const approvalReasonCategorySchema = z.union([
  z.literal('art'),
  z.literal('order'),
  z.literal('art_and_order'),
  z.literal('other'),
]);

export const approvalStatusSchema = z.union([
  z.literal('Pending'),
  z.literal('Approved'),
  z.literal('Declined'),
]);

export const artRequestActivityTypeSchema = z.union([
  z.literal('sew_out'),
  z.literal('vectorize'),
  z.literal('digitize'),
]);

export const artRequestStatusSchema = z.union([
  z.literal('pending'),
  z.literal('pending_activity'),
  z.literal('completed'),
  z.literal('rejected'),
]);

export const artRequestTypeSchema = z.union([z.literal('proof'), z.literal('release')]);

export const bankAccountTypeSchema = z.union([
  z.literal('businessChecking'),
  z.literal('businessSavings'),
  z.literal('personalChecking'),
  z.literal('personalSavings'),
]);

export const billMatchStatusSchema = z.union([
  z.literal('no_purchase_order'),
  z.literal('matched'),
  z.literal('waiting_on_invoice'),
  z.literal('waiting_on_credit'),
  z.literal('to_review'),
]);

export const billPaidStatusSchema = z.union([
  z.literal('on_hold'),
  z.literal('paid'),
  z.literal('partial'),
  z.literal('unpaid'),
]);

export const clientNameSchema = z.union([
  z.literal('external_erp'),
  z.literal('external_portal'),
  z.literal('internal_erp'),
  z.literal('internal_portal'),
]);

export const commissionHitSchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
]);

export const commissionMethodSchema = z.union([z.literal('gp'), z.literal('cogs')]);

export const complexityUnitSchema = z.union([
  z.literal('colors'),
  z.literal('stitches'),
  z.literal('other'),
]);

export const costCategorySchema = z.union([
  z.literal('product'),
  z.literal('screenprint'),
  z.literal('embroidery'),
  z.literal('other_decoration'),
  z.literal('art'),
  z.literal('inbound_shipping'),
  z.literal('outbound_shipping'),
  z.literal('packout'),
  z.literal('payment'),
  z.literal('rush'),
  z.literal('other'),
]);

export const customerPaymentMethodSchema = z.union([
  z.literal('card'),
  z.literal('check'),
  z.literal('ach'),
  z.literal('card_present'),
  z.literal('cash'),
  z.literal('other'),
]);

export const decorationMethodSchema = z.union([
  z.literal('screenprint'),
  z.literal('embroidery'),
  z.literal('ad_specialty'),
  z.literal('heat_transfer'),
  z.literal('blanks'),
  z.literal('patch'),
]);

export const fulfillmentOrderStatusSchema = z.union([
  z.literal('pending'),
  z.literal('waiting'),
  z.literal('issued'),
  z.literal('processing'),
  z.literal('shipped'),
  z.literal('voided'),
  z.literal('backordered'),
]);

export const intervalSchema = z.union([
  z.literal('day'),
  z.literal('week'),
  z.literal('month'),
  z.literal('quarter'),
  z.literal('year'),
]);

export const inventoryEntryTypeSchema = z.union([
  z.literal('add'),
  z.literal('move'),
  z.literal('subtract'),
  z.literal('scrap'),
]);

export const inventoryLocationTypeSchema = z.union([
  z.literal('bin'),
  z.literal('overstock'),
  z.literal('pallet'),
  z.literal('temporary'),
  z.literal('multi_bin'),
  z.literal('pickable_pallet'),
]);

export const issueStatusSchema = z.union([
  z.literal('open'),
  z.literal('in_process'),
  z.literal('to_process'),
  z.literal('resolved'),
]);

export const itemClassificationSchema = z.union([
  z.literal('product'),
  z.literal('service'),
  z.literal('apparel'),
  z.literal('shoes'),
  z.literal('hats'),
  z.literal('shipping'),
  z.literal('decoration'),
  z.literal('merchant_fees'),
  z.literal('fulfillment_storage'),
  z.literal('fulfillment_picks'),
  z.literal('other'),
]);

export const membershipRoleSchema = z.union([
  z.literal('avail_incognito'),
  z.literal('avail'),
  z.literal('admin'),
  z.literal('member'),
  z.literal('guest'),
]);

export const orderApprovalStatusSchema = z.union([
  z.literal('none'),
  z.literal('no'),
  z.literal('proof_requested'),
  z.literal('approval_sent'),
  z.literal('yes'),
]);

export const orderDepartmentStatusSchema = z.union([
  z.literal('none'),
  z.literal('no'),
  z.literal('partial'),
  z.literal('issue'),
  z.literal('yes'),
]);

export const orderStatusSchema = z.union([
  z.literal('Waiting on Customer Payment'),
  z.literal('Waiting on Customer PO'),
  z.literal('Cancelled'),
  z.literal('Paid'),
  z.literal('Partially Paid'),
  z.literal('Invoiced'),
  z.literal('Has Issue'),
  z.literal('Proof Requested'),
  z.literal('Approval Sent'),
  z.literal('Unapproved'),
  z.literal('To Be Purchased'),
  z.literal('Release Requested'),
  z.literal('Pending Production Approval'),
  z.literal('To Be Released'),
  z.literal('Waiting on Product'),
  z.literal('In Production'),
  z.literal('Ready to Produce'),
  z.literal('Waiting on Packout'),
  z.literal('Ready to Ship'),
  z.literal('Pending Webstore Import'),
  z.literal('Complete'),
]);

export const orderStatusIdSchema = z.union([
  z.literal(1),
  z.literal(10),
  z.literal(11),
  z.literal(20),
  z.literal(30),
  z.literal(40),
  z.literal(41),
  z.literal(42),
  z.literal(43),
  z.literal(44),
  z.literal(60),
  z.literal(70),
  z.literal(75),
  z.literal(80),
  z.literal(99),
]);

export const poAttachmentModeSchema = z.union([
  z.literal('links'),
  z.literal('attachments'),
  z.literal('zip'),
]);

export const poStatusSchema = z.union([
  z.literal('Has Issue'),
  z.literal('Unsubmitted'),
  z.literal('Submitted'),
  z.literal('Received'),
  z.literal('Partially Received'),
]);

export const paymentApplicationStatusSchema = z.union([
  z.literal('applied'),
  z.literal('partial'),
  z.literal('unapplied'),
]);

export const paymentTypeSchema = z.union([
  z.literal('payment'),
  z.literal('memo'),
  z.literal('deduction'),
  z.literal('bad_debt'),
]);

export const pickReadyStatusSchema = z.union([
  z.literal('ready'),
  z.literal('some_not_pickable'),
  z.literal('some_unavailable'),
  z.literal('not_pickable'),
  z.literal('unavailable'),
]);

export const pickStatusSchema = z.union([
  z.literal('issued'),
  z.literal('on_hold'),
  z.literal('picking'),
  z.literal('pending_shipment'),
  z.literal('complete'),
  z.literal('cancellation_requested'),
]);

export const productChargeTypeSchema = z.union([
  z.literal('Setup'),
  z.literal('Run'),
  z.literal('Order'),
]);

export const productTypeSchema = z.union([
  z.literal('product'),
  z.literal('service'),
  z.literal('decorated'),
  z.literal('decoration'),
]);

export const productionEventStatusSchema = z.union([
  z.literal('Scheduled'),
  z.literal('Unscheduled'),
  z.literal('Setting Up'),
  z.literal('To Be Approved'),
  z.literal('Ready to Start'),
  z.literal('Started'),
  z.literal('To Be Packed'),
  z.literal('Packing'),
  z.literal('Completed'),
]);

export const projectActivityTypeSchema = z.union([
  z.literal('created'),
  z.literal('duplicated'),
  z.literal('messaged'),
  z.literal('added_user'),
  z.literal('removed_user'),
  z.literal('back_to_draft'),
  z.literal('back_to_options_requested'),
  z.literal('back_to_options_provided'),
  z.literal('requested_options'),
  z.literal('provided_options'),
  z.literal('requested_proofs'),
  z.literal('ordered'),
  z.literal('cancelled'),
  z.literal('uploaded_po'),
  z.literal('approved_order'),
  z.literal('rejected_order'),
  z.literal('updated'),
  z.literal('item_added'),
  z.literal('item_removed'),
  z.literal('item_updated'),
  z.literal('sample_shipped'),
  z.literal('sample_delivered'),
]);

export const projectBudgetTypeSchema = z.union([z.literal('total'), z.literal('per_person')]);

export const projectStatusSchema = z.union([
  z.literal('draft'),
  z.literal('options_requested'),
  z.literal('options_provided'),
  z.literal('proofs_requested'),
  z.literal('ordered'),
  z.literal('complete'),
  z.literal('cancelled'),
]);

export const projectTypeSchema = z.union([
  z.literal('project'),
  z.literal('self-service'),
  z.literal('webstore'),
  z.literal('fulfill_store'),
]);

export const proofTypeSchema = z.union([z.literal('customer'), z.literal('production')]);

export const purchaseOrderTypeSchema = z.union([
  z.literal('general'),
  z.literal('blanks'),
  z.literal('subcontract'),
  z.literal('drops'),
]);

export const quoteStatusSchema = z.union([
  z.literal('open'),
  z.literal('declined'),
  z.literal('accepted'),
  z.literal('ordered'),
]);

export const rebateCalculationMethodSchema = z.union([
  z.literal('fulfillment'),
  z.literal('fundraise'),
]);

export const returnStatusSchema = z.union([
  z.literal('awaiting'),
  z.literal('processing'),
  z.literal('completed'),
  z.literal('cancelled'),
]);

export const shipToPartySchema = z.union([
  z.literal('avail'),
  z.literal('subcontractor'),
  z.literal('customer'),
]);

export const shippingModeSchema = z.union([
  z.literal('bulk'),
  z.literal('kits'),
  z.literal('inventory'),
  z.literal('inventory_kit'),
]);

export const shippoFileTypeSchema = z.union([
  z.literal('PDF'),
  z.literal('PNG'),
  z.literal('PDF_4x6'),
  z.literal('ZPLII'),
]);

export const shoppingModeSchema = z.union([
  z.literal('blank'),
  z.literal('decorated'),
  z.literal('stortal'),
  z.literal('both'),
]);

export const transactionTypeSchema = z.union([
  z.literal('bill'),
  z.literal('check'),
  z.literal('expense'),
  z.literal('bill_payment'),
  z.literal('transfer'),
]);

export const userChangeRequestStatusSchema = z.union([
  z.literal('pending'),
  z.literal('approved'),
  z.literal('rejected'),
]);

export const vendorImportTypeSchema = z.union([z.literal('products'), z.literal('inventory')]);

export const vendorPaymentMethodSchema = z.union([
  z.literal('check'),
  z.literal('ach'),
  z.literal('eft'),
  z.literal('eft_autopay'),
  z.literal('credit_card'),
  z.literal('credit_card_autopay'),
  z.literal('mercury_ach'),
]);

export const webstoreModeSchema = z.union([
  z.literal('fulfillment'),
  z.literal('fulfillment_production'),
  z.literal('popup'),
  z.literal('ondemand'),
]);

export const giftStatusSchema = z.union([
  z.literal('pending'),
  z.literal('failed'),
  z.literal('sent'),
  z.literal('email_delivered'),
  z.literal('email_failed'),
  z.literal('viewed'),
  z.literal('redeemed'),
  z.literal('shipped'),
  z.literal('delivered'),
  z.literal('cancelled'),
]);

export const integrationTypeSchema = z.union([
  z.literal('hubspot'),
  z.literal('salesforce'),
  z.literal('slack'),
  z.literal('teams'),
  z.literal('workday'),
  z.literal('proliant'),
]);

export const giftingProductTypeSchema = z.union([
  z.literal('kit'),
  z.literal('inventory'),
  z.literal('on_demand'),
  z.literal('batch'),
  z.literal('gift_card'),
]);
