import { ChangeEvent, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Card,
  CardActions,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import map from 'lodash/map';
import toString from 'lodash/toString';
import without from 'lodash/without';
import { useSessionStorage } from 'usehooks-ts';
import { useGetBankAccounts } from '@/api/accounts';
import { useGetChecksToPrint, useMarkPrinted, usePrintChecks } from '@/api/transactions';
import PaginatedTable from '@/components/DataTable/PaginatedTable';
import Paper from '@/components/Shared/Paper';
import TextLink from '@/components/Text/TextLink';
import { VENDOR_PAYMENT_METHODS } from '@/constants';
import { useTitle } from '@/contexts/AppContext';
import { Transaction, VendorPaymentMethod, vendorPaymentMethodSchema } from '@/types';
import curr from '@/utils/curr';
import { formatDate } from '@/utils/dates';
import numString from '@/utils/numString';

const columnHelper = createColumnHelper<Transaction>();
export default function PrintChecks() {
  const [paymentMethod, setPaymentMethod] = useState<VendorPaymentMethod>('check');
  const [bankAccountId, setBankAccountId] = useSessionStorage<number | undefined>(
    'checksToPrintBankAccountId',
    undefined,
  );
  const [checkNumber, setCheckNumber] = useState<string>();
  const [selected, setSelected] = useState<number[]>([]);

  useTitle('Print Checks', '/transactions');

  const { data: transactions = [] } = useGetChecksToPrint(bankAccountId, paymentMethod);
  const { data: bankAccounts = [] } = useGetBankAccounts();
  const bankAccount = bankAccounts.find((a) => a.id === bankAccountId);

  useEffect(() => {
    if (transactions) {
      setSelected(transactions.map((r) => r.id));
    }
  }, [transactions]);

  useEffect(() => {
    if (bankAccount && bankAccount.next_check_number) {
      setCheckNumber(String(bankAccount.next_check_number));
    }
  }, [bankAccount]);

  const onToggleAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(map(transactions, 'id'));
    } else {
      setSelected([]);
    }
  };

  const onToggle = (id: number) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected((prev) => [...prev, id]);
    } else {
      setSelected((prev) => without(prev, id));
    }
  };

  const printRequest = usePrintChecks();
  const markPrintedRequest = useMarkPrinted();

  const onPrint = () => {
    if (!bankAccountId || !checkNumber) {
      alert('Please select a bank account and starting check number');
      return;
    }

    printRequest.mutate({
      bank_account_id: bankAccountId,
      starting_check_number: Number(checkNumber),
      transaction_ids: selected,
      payment_method: paymentMethod,
    });
  };

  const onMarkSent = () => {
    markPrintedRequest.mutate({
      bank_account_id: bankAccountId!,
      transaction_ids: selected,
      payment_method: paymentMethod,
    });
  };

  if (!transactions) {
    return <CircularProgress />;
  }

  return (
    <Stack spacing={2}>
      <Paper>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 4, lg: 3 }}>
            <FormControl variant="outlined" sx={{ mr: 2 }} fullWidth>
              <InputLabel id="payment-method-label">Payment Method</InputLabel>
              <Select
                labelId="payment-method-label"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value as VendorPaymentMethod)}
                label="Payment Method"
              >
                {vendorPaymentMethodSchema.options.map((o) => (
                  <MenuItem key={o.value} value={o.value}>
                    {VENDOR_PAYMENT_METHODS[o.value]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, md: 4, lg: 3 }}>
            <FormControl variant="outlined" sx={{ mr: 2 }} fullWidth>
              <InputLabel id="bank-account-label">Bank Account</InputLabel>
              <Select
                labelId="bank-account-label"
                value={bankAccountId}
                onChange={(e) => setBankAccountId(Number(e.target.value))}
                label="Bank Account"
              >
                {bankAccounts.map((a) => (
                  <MenuItem value={a.id} key={a.id}>
                    {a.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {paymentMethod === 'check' && (
            <Grid size={{ xs: 12, md: 4, lg: 3 }}>
              <TextField
                fullWidth
                label="Starting Check #"
                variant="outlined"
                onChange={(e) => setCheckNumber(e.target.value)}
                value={toString(checkNumber)}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <Card>
        <PaginatedTable
          storageKey="checksToPrint"
          rows={transactions}
          columns={[
            columnHelper.display({
              id: 'checkbox',
              header: () => (
                <Checkbox
                  checked={selected.length > 0 && transactions.length === selected.length}
                  indeterminate={selected.length > 0 && selected.length < transactions.length}
                  onChange={onToggleAll}
                />
              ),
              cell: ({ row }) => (
                <Checkbox
                  checked={selected.includes(row.original.id)}
                  onChange={onToggle(row.original.id)}
                />
              ),
              enableHiding: false,
            }),
            columnHelper.accessor('transaction_date', {
              header: 'Date',
              cell: ({ getValue }) => formatDate(getValue()),
            }),
            columnHelper.accessor('label', {
              header: 'Transaction',
              cell: ({ row, getValue }) => (
                <TextLink to={`/transactions/${row.original.id}`}>{getValue()}</TextLink>
              ),
            }),
            columnHelper.accessor('vendor.name', {
              header: 'Vendor',
            }),
            columnHelper.accessor('total', {
              header: 'Amount',
              cell: ({ getValue }) => curr(getValue()),
            }),
          ]}
        />
        <CardActions>
          <LoadingButton
            variant="contained"
            disabled={selected.length === 0}
            onClick={paymentMethod === 'check' ? onPrint : onMarkSent}
            loading={printRequest.isLoading || markPrintedRequest.isLoading}
          >
            {paymentMethod === 'check'
              ? `Print ${numString(selected.length, 'Checks')}`
              : `Mark ${numString(selected.length, 'Payments')} as Sent`}
          </LoadingButton>
        </CardActions>
      </Card>
    </Stack>
  );
}
