import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Table, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { z } from 'zod';
import FieldFactory from '@/classes/FieldFactory';
import Can from '@/components/Permissions/Can';
import Label from '@/components/Shared/Label';
import { useDialogs } from '@/contexts/DialogContext';
import { Packout, PartiallyRequired } from '@/types';
import curr from '@/utils/curr';
import { formatDate } from '@/utils/dates';
import { useOnReloadRecord } from '@/utils/genericResource';

export default function PackoutTimingTable({
  packout,
}: {
  packout: PartiallyRequired<Packout, 'timing'>;
}) {
  const { prompt } = useDialogs();
  const reloadRecord = useOnReloadRecord();

  const onSubmit = (v: Partial<Packout>) =>
    axios.put(`/api/order-packouts/${packout.id}`, v).then(() => {
      reloadRecord();
    });

  const onUpdateEarliest = () => {
    prompt({
      title: 'Update Packout Timing',
      fields: [
        FieldFactory.date('earliest_ship_date')
          .withLabel('Earliest Ship Date')
          .withHelp(
            'Setting this will ensure we do not ship kits before this date. It will also allow us to prioritize other packouts ahead of this one.',
          ),
      ],
      initialValues: {
        earliest_ship_date: packout.earliest_ship_date,
      },
      schema: z.object({
        earliest_ship_date: z.string().date().nullable(),
      }),
      onSubmit,
    });
  };

  const onUpdateDropDead = () => {
    prompt({
      title: 'Update Packout Timing',
      fields: [
        FieldFactory.date('drop_dead_date')
          .withLabel('Drop Dead Date')
          .withHelp(
            'Moving this up will prioritize this packout.  However, if this date is before our normal turnaround time, you will be charged rush fees.',
          ),
      ],
      initialValues: {
        drop_dead_date: packout.drop_dead_date,
      },
      schema: z.object({
        drop_dead_date: z.string().date().nullable(),
      }),
      onSubmit,
    });
  };

  const onSchedule = () => {
    prompt({
      title: 'Schedule Packout',
      fields: [FieldFactory.date('scheduled_date').withLabel('Scheduled Date')],
      initialValues: {
        scheduled_date: packout.scheduled_date,
      },
      schema: z.object({
        scheduled_date: z.string().date().nullable(),
      }),
      onSubmit: (v) =>
        axios.put(`/api/order-packouts/${packout.id}/schedule`, v).then(() => {
          reloadRecord();
        }),
    });
  };

  const { timing, order } = packout;
  return (
    <Table>
      <TableRow>
        <TableCell variant="head">Earliest Ship Date</TableCell>

        <TableCell>
          <Box display="flex" alignItems="center" gap={1} justifyContent="space-between">
            {formatDate(packout.earliest_ship_date)}

            {!order.invoiced_at && packout.status === 'pending' && (
              <IconButton onClick={onUpdateEarliest} size="small" sx={{ my: -1 }}>
                <EditIcon />
              </IconButton>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">Drop Dead Date</TableCell>

        <TableCell>
          <Box display="flex" alignItems="center" gap={1} justifyContent="space-between">
            {formatDate(packout.drop_dead_date)}

            {!order.invoiced_at && packout.status === 'pending' && (
              <IconButton onClick={onUpdateDropDead} size="small" sx={{ my: -1 }}>
                <EditIcon />
              </IconButton>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">
          Ready Date {timing.is_estimate && <Label>Estimate</Label>}
        </TableCell>
        <TableCell>{formatDate(timing.ready_date)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">
          Standard Ship Date {timing.is_estimate && <Label>Estimate</Label>}
        </TableCell>
        <TableCell>{formatDate(timing.standard_ship_date)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head">
          Committed Ship Date {timing.is_estimate && <Label>Estimate</Label>}
        </TableCell>
        <TableCell>{formatDate(timing.committed_ship_date)}</TableCell>
      </TableRow>
      {timing.rush_fees > 0 && (
        <TableRow>
          <TableCell variant="head">{timing.rush_days}-Day Rush Fees</TableCell>
          <TableCell>{curr(timing.rush_fees)}</TableCell>
        </TableRow>
      )}
      <Can permission="write:packouts">
        <TableRow>
          <TableCell variant="head">Scheduled Date</TableCell>
          <TableCell>
            <Box display="flex" alignItems="center" gap={1} justifyContent="space-between">
              {formatDate(packout.scheduled_date)}
              <IconButton onClick={onSchedule} size="small" sx={{ my: -1 }}>
                <EditIcon />
              </IconButton>
            </Box>
          </TableCell>
        </TableRow>
      </Can>
    </Table>
  );
}
