import { useState } from 'react';
import {
  List,
  ListItemButton,
  ListItemText,
  Grid2 as Grid,
  Button,
  ListItemIcon,
  Checkbox,
  Card,
  CardHeader,
  Box,
} from '@mui/material';
import pickBy from 'lodash/pickBy';
import { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import FieldFactory from '@/classes/FieldFactory';
import Form from '@/components/Form/Form';
import FormField from '@/components/Form/FormField';
import Paper from '@/components/Shared/Paper';
import { useTitle } from '@/contexts/AppContext';
import getApiUrl from '@/utils/getApiUrl';

interface AccountingReport {
  name: string;
  key: string;
  group?: boolean;
}

const REPORTS: AccountingReport[] = [
  {
    name: 'Income Statement',
    key: 'income-statement',
    group: true,
  },
  {
    name: 'Balance Sheet',
    key: 'balance-sheet',
    group: true,
  },
  {
    name: 'Cash Flow',
    key: 'cash-flow',
    group: true,
  },
  {
    name: 'Avail Fee Analysis',
    key: 'avail-fee-analysis',
  },
  {
    name: 'Scrap Analysis',
    key: 'scrap-analysis',
  },
];

interface FormShape {
  date_range: [Moment, Moment];
  group: string;
  include_account_numbers: boolean;
  format?: string;
}

export default function AccountingReports() {
  const [selected, setSelected] = useState<AccountingReport>();

  useTitle('Accounting Reports');

  const form = useForm<FormShape>();

  const runReport = ({ date_range: dates, group, include_account_numbers, format }: FormShape) => {
    if (!selected) {
      return;
    }
    const searchParams = new URLSearchParams(
      pickBy({
        start_date: dates[0].format('YYYY-MM-DD'),
        end_date: dates[1].format('YYYY-MM-DD'),
        group,
        include_account_numbers: include_account_numbers ? '1' : '0',
        format: format || 'pdf',
      }),
    );
    window.open(getApiUrl(`/api/accounting-reports/${selected.key}?${searchParams.toString()}`));
  };

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 8 }}>
        <Card>
          <CardHeader title="Available Reports to Run" titleTypographyProps={{ variant: 'h6' }} />
          <List>
            {REPORTS.map((r) => (
              <ListItemButton onClick={() => setSelected(r)} key={r.key}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={r.key === selected?.key}
                    onChange={(e) => setSelected(e.target.checked ? r : undefined)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={r.name} />
              </ListItemButton>
            ))}
          </List>
        </Card>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        {selected && (
          <Paper title={selected.name}>
            <Form form={form} onSubmit={runReport}>
              <Box mb={2}>
                <FormField field={FieldFactory.daterange('date_range')} />
              </Box>

              {selected.group && (
                <Box mb={2}>
                  <FormField
                    field={FieldFactory.radio('group', {
                      '': 'None',
                      month: 'Month',
                      quarter: 'Quarter',
                    }).withLabel('Columns')}
                  />
                </Box>
              )}

              <Box mb={2}>
                <FormField
                  field={FieldFactory.boolean('include_account_numbers', 'Include Account Numbers')}
                />
              </Box>

              <Button
                type="button"
                variant="contained"
                onClick={form.handleSubmit((v) => runReport({ format: 'pdf', ...v }))}
              >
                Download PDF
              </Button>
              <Button
                type="button"
                onClick={form.handleSubmit((v) => runReport({ format: 'xlsx', ...v }))}
                sx={{ ml: 2 }}
              >
                Download XLSX
              </Button>
            </Form>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
