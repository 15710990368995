import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Close, DragHandle } from '@mui/icons-material';
import { Box, IconButton, TableCell, TableRow, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { FieldFactory } from '@/classes';
import ColorChooserField from '@/components/Designs/ColorChooserField';
import StockColorIcon from '@/components/Designs/StockColorIcon';
import FormField from '@/components/Form/FormField';
import WarningIcon from '@/components/Shared/WarningIcon';
import { DesignPayloadWithRelations } from '@/local-types';
import { ComplexityUnit, Customer } from '@/types';
import { isScreenPrint } from '@/utils/helpers';
import numString from '@/utils/numString';

export const ALPHA = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
];
const SQUEEGEE = ['Red', 'Green', 'Yellow', 'Blue', 'Orange'];

export type FlexibleControl = Control<Pick<DesignPayloadWithRelations, 'colors'>>;

export default function DesignColorRow({
  id,
  index,
  decorationUnit,
  customer,
  freeSolo,
  control,
  onRemove,
  shouldDisable = false,
}: {
  id: string;
  index: number;
  decorationUnit: ComplexityUnit | null;
  customer?: Customer;
  freeSolo?: boolean;
  control: FlexibleControl;
  onRemove?: () => void;
  shouldDisable?: boolean;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });
  const field = `colors.${index}` as const;
  const theme = useTheme();

  return (
    <TableRow
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <TableCell padding="checkbox">
        <DragHandle {...attributes} {...listeners} />
      </TableCell>
      <TableCell style={{ width: 100 }}>
        <FormField
          field={FieldFactory.select('code', ALPHA).setDisabled(shouldDisable).withoutLabel()}
          parentName={field}
        />
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <FormField
              field={new ColorChooserField('color').setDisabled(shouldDisable).withoutLabel().with({
                freeSolo,
                customer,
                decorationUnit,
              })}
              parentName={field}
            />
          </Box>
          {onRemove && (
            <Controller
              name={`${field}.ink_changes_count`}
              control={control}
              render={({ field }) => (
                <>
                  {field.value ? (
                    <Box ml={1} pt={1}>
                      <WarningIcon
                        message={`This color is being changed in ${numString(field.value, 'designs')}`}
                      />
                    </Box>
                  ) : null}
                </>
              )}
            />
          )}
          <Controller
            name={`${field}.stock_color`}
            control={control}
            disabled={shouldDisable}
            render={({ field }) => (
              <>{field.value && <StockColorIcon stockColor={field.value} />}</>
            )}
          />
        </Box>
      </TableCell>
      {isScreenPrint(decorationUnit) && (
        <>
          <TableCell>
            <FormField field={FieldFactory.number('mesh').withoutLabel()} parentName={field} />
          </TableCell>

          <TableCell padding="none">
            <FormField
              field={FieldFactory.select('squeegee', SQUEEGEE).withoutLabel().with({
                includeBlank: 'None',
              })}
              parentName={field}
            />
          </TableCell>

          <TableCell>
            <FormField field={FieldFactory.boolean('flash').withoutLabel()} parentName={field} />
          </TableCell>
        </>
      )}

      {onRemove && (
        <TableCell
          style={{
            position: 'sticky',
            right: '0',
            width: 'auto',
            backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
            zIndex: '1',
          }}
        >
          <IconButton onClick={onRemove} size="large">
            <Close fontSize="small" />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
}
