import { LicenseInfo } from '@mui/x-license';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import LaravelEcho from 'laravel-echo';
import Pusher from 'pusher-js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { z } from 'zod';
import AuthenticatedApp from '@/AuthenticatedApp';
import VendorRequestForm from '@/pages/VendorRequestForm';
import ForgotPassword from '@/pages/auth/ForgotPassword';
import Login from '@/pages/auth/Login';
import Register from '@/pages/auth/Register';
import ResetPassword from '@/pages/auth/ResetPassword';
import SplashScreen from './SlashScreen';

declare global {
  interface Window {
    Pusher: typeof Pusher;
    Echo: LaravelEcho;
    artRequestMeta:
      | {
          _customerProofCount: number[];
          _productionProofCount: number[];
          _completedAts: (string | null)[];
        }
      | undefined;
  }
}

LicenseInfo.setLicenseKey(
  'b5c2d293b9c338749259ef1769104feeTz05NzMyNSxFPTE3NTY4MzE2MjQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
);

window.Pusher = Pusher;

z.setErrorMap((error, ctx) => {
  if (error.code === z.ZodIssueCode.invalid_type) {
    if (error.received === 'nan' || error.received === 'undefined' || error.received === 'null') {
      return { message: 'This field is required' };
    }
  }

  return { message: ctx.defaultError };
});

export default function App() {
  const { isLoading } = useQuery(['xsrf'], () => axios.get('/api/touch').then(() => true));

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <GoogleOAuthProvider clientId="944741344614-o51br5nmah6uc6k07r00i3onlukjvn4b.apps.googleusercontent.com">
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register/:code" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/vendor-requests/:token" element={<VendorRequestForm />} />

            <Route path="*" element={<AuthenticatedApp />} />
          </Routes>
        </QueryParamProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}
