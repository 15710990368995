import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { PendingPayout } from '@/local-types';
import { Business, BusinessCredit, BusinessPayout, OrderPayment, PurchaseOrder } from '@/types';

export interface DetailedPendingPayout {
  business: Business;
  lines: {
    id: number;
    customer_name: string;
    order_id: number;
    order_description: string;
    amount: number;
  }[];
  salespeople: { id: number; user_name: string; amount: number }[];
  deductions: OrderPayment[];
  credits: BusinessCredit[];
  purchase_orders: PurchaseOrder[];
  history: BusinessPayout[];
}

export function useGetPendingPayouts() {
  return useQuery(['pendingPayouts'], () =>
    axios.get<{ data: PendingPayout[] }>('/api/pending-payouts').then(({ data }) => data.data),
  );
}

export function useGetPendingPayout(businessId: number | undefined) {
  return useQuery(
    ['pendingPayout', businessId],
    () =>
      axios
        .get<DetailedPendingPayout>(`/api/pending-payouts/${businessId}`)
        .then((res) => res.data),
    {
      enabled: Boolean(businessId),
    },
  );
}

export function useCreatePayout() {
  const queryClient = useQueryClient();
  return useMutation(
    (businessId: number) => axios.post('/api/pending-payouts', { business_id: businessId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pendingPayouts']);
      },
    },
  );
}

export function useGetPayoutHistory(businessId: number | undefined) {
  return useQuery(
    ['payoutHistory', businessId],
    () =>
      axios
        .get<{ history: BusinessPayout[] }>(`/api/pending-payouts/${businessId}`)
        .then(({ data }) => data.history),
    {
      enabled: Boolean(businessId),
    },
  );
}
