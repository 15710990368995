import { ChangeEvent, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import omit from 'lodash/omit';
import { DefaultValues } from 'react-hook-form';
import { z } from 'zod';
import { useCreateOrderItemRequest } from '@/api/orderItems';
import { FieldFactory } from '@/classes';
import DrawerButtons from '@/components/Form/DrawerButtons';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import Form from '@/components/Form/Form';
import FormErrors from '@/components/Form/FormErrors';
import FormField from '@/components/Form/FormField';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { orderItemWizardRowSchema, ProductWithVariants } from '@/local-types';
import { genericModelReferenceSchema, OrderItemPayload, orderItemPayloadSchema } from '@/types';
import { useZodForm } from '@/utils/form';
import BehaviorSection from './Create/BehaviorSection';
import ItemsSection from './Create/ItemsSection';
import ProductSection from './Create/ProductSection';
import VendorSection from './Create/VendorSection';

const formSchema = orderItemPayloadSchema
  .omit({
    variant: true,
    qty: true,
    price: true,
    cost: true,
  })
  .extend({
    product: genericModelReferenceSchema.nullish(),
    items: z.preprocess((i) => {
      return Array.isArray(i) ? i.filter((i) => !isNaN(i.qty) && i.qty != '' && i.qty != null) : [];
    }, z.array(orderItemWizardRowSchema)),
  });

type FormShape = z.infer<typeof formSchema>;

export default function OrderItemWizardDrawer({
  open,
  onClose,
  initialValues,
}: {
  open: boolean;
  onClose: () => void;
  initialValues: DefaultValues<FormShape>;
}) {
  const form = useZodForm(formSchema, initialValues);
  const product = form.watch('product') as ProductWithVariants | undefined;
  const isCustom = form.watch('is_custom') || false;
  const createRequest = useCreateOrderItemRequest();

  useEffect(() => {
    if (open) {
      form.reset(initialValues);
    }
  }, [open]);

  // Handle Product Change
  useEffect(() => {
    if (form.formState.isDirty && product) {
      form.setValue('description', product.name);
      form.setValue('is_shippable', product.default_is_shippable);
      form.setValue('can_drop_ship', product.default_can_drop_ship);
      form.setValue('can_apply_designs', product.default_can_apply_designs);
      form.setValue('is_purchasable', product.default_is_purchasable);
      form.setValue('show_on_invoice', product.default_show_on_invoice);
      form.setValue('vendor', product.vendor);
      form.setValue('classification', product.classification);
      form.setValue(
        'items',
        product.variants.map((v) => ({
          variant: v,
          size: v.size,
          price: v.price,
          cost: v.cost,
        })),
      );
    }
  }, [product]);

  const onSubmit = (values: FormShape) => {
    const payload = values.items.map((item) => ({
      ...omit(values, ['items']),
      ...item,
    })) as OrderItemPayload[];

    return createRequest.mutateAsync({ items: payload }).then(() => {
      onClose();
    });
  };

  const handleCustomChange = (e: ChangeEvent<HTMLInputElement>) => {
    const booleanValue = e.currentTarget.value === 'custom';
    form.setValue('is_custom', booleanValue);
    if (booleanValue) {
      form.setValue('product', null);
    }
  };

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Add Product Wizard" width={600}>
      {/* @ts-expect-error idk */}
      <Form form={form} onSubmit={onSubmit}>
        <ProductSection handleCustomChange={handleCustomChange} isCustom={isCustom} />

        <VendorSection hideCost isCustom={isCustom} />

        <DrawerFormSection title="Quantities and Price">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Size</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <ItemsSection isCustom={isCustom} />
          </Table>

          <FormField field={FieldFactory.percent('discount')} />
        </DrawerFormSection>

        <BehaviorSection />

        <FormErrors form={form} />

        <DrawerButtons>
          <LoadingButton type="submit" variant="contained" loading={form.formState.isSubmitting}>
            Create
          </LoadingButton>
        </DrawerButtons>
      </Form>
    </ClosableDrawer>
  );
}
