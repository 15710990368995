import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { onUpload } from '@/AuthenticatedApp';

export function useUploadRequest(type = 'file') {
  return useMutation((file: Blob) => onUpload([file], type).then((res) => res[0]));
}

export function useGetFabricRequest(image: string) {
  return useQuery(
    ['fabricState', image],
    () =>
      axios
        .get<string | null>(`/api/fabric-states/${encodeURIComponent(image)}`)
        .then(({ data }) => data),
    {
      retry: false,
    },
  );
}

export function useSaveFabricRequest() {
  return useMutation((data: { path: string; fabric: string }) => {
    return axios.post('/api/fabric-states', data);
  });
}
