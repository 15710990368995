import { Grid2 as Grid, Stack } from '@mui/material';
import EventableCard from '@/components/Notes/EventableCard';
import POMatchingCard from '@/components/Transactions/POMatchingCard';
import PaymentSentStatusCard from '@/components/Transactions/PaymentSentStatusCard';
import { useRecord } from '@/utils/genericResource';
import BillPaymentsCard from './BillPaymentsCard';
import TransactionFields from './TransactionFields';

export default function TransactionEditor() {
  const { id, type } = useRecord('transactions');

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 9 }}>
        <TransactionFields />
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <Stack spacing={2}>
          {['check', 'bill_payment'].includes(type) && <PaymentSentStatusCard />}
          {type !== 'bill_payment' && <POMatchingCard />}
          {type === 'bill' && <BillPaymentsCard />}
          <EventableCard resource="transactions" resourceId={id} size="small" />
        </Stack>
      </Grid>
    </Grid>
  );
}
