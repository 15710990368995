import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import DesignColorRow from '@/components/Designs/DesignColorRow';
import { FlexibleControl } from '@/components/Designs/DesignColorRow';
import { ALPHA } from '@/components/Designs/DesignColorRow';
import { DesignPayloadWithRelations } from '@/local-types';
import { ComplexityUnit, Customer, DesignColorPayload } from '@/types';
import { isScreenPrint } from '@/utils/helpers';

export default function DesignColors({
  decorationUnit,
  customer,
  freeSolo = true,
}: {
  decorationUnit: ComplexityUnit | null;
  customer?: Customer;
  freeSolo?: boolean;
}) {
  const form = useFormContext<DesignPayloadWithRelations>();
  const { append, move, fields, remove } = useFieldArray({
    control: form.control,
    name: 'colors',
  });

  const onSortEnd = ({ active, over }: DragEndEvent) => {
    if (!over) {
      return;
    }
    const oldIndex = fields.findIndex((c) => c.id === active.id);
    const newIndex = fields.findIndex((c) => c.id === over.id);
    move(oldIndex, newIndex);
  };

  const getNewColor = (): DesignColorPayload => ({
    code: ALPHA[fields.length],
    color: '',
    mesh: null,
    squeegee: null,
    flash: false,
  });

  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Code</TableCell>
              <TableCell style={{ minWidth: 300 }}>Color</TableCell>
              {isScreenPrint(decorationUnit) && (
                <>
                  <TableCell style={{ minWidth: 100 }}>Mesh</TableCell>
                  <TableCell>Squeegee</TableCell>
                  <TableCell>Flash</TableCell>
                </>
              )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <DndContext onDragEnd={onSortEnd}>
              <SortableContext items={fields.map((c) => c.id)}>
                {fields.map((color, idx) => (
                  <DesignColorRow
                    key={color.id}
                    id={color.id}
                    index={idx}
                    decorationUnit={decorationUnit}
                    customer={customer}
                    freeSolo={freeSolo}
                    onRemove={() => remove(idx)}
                    control={form.control as unknown as FlexibleControl}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        onClick={() => append(getNewColor())}
        style={{ marginTop: 4 }}
        size="small"
        color="secondary"
      >
        Add New
      </Button>
    </div>
  );
}
