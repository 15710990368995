import { SyntheticEvent, useEffect, useState } from 'react';
import { Assignment } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Can from '@/components/Permissions/Can';
import { ProductWithVariants } from '@/local-types';
import { ProductVariant } from '@/types';
import curr from '@/utils/curr';
import formatNumber from '@/utils/formatNumber';

export default function ProductVariantsTable({
  product,
  onSelect,
}: {
  product: ProductWithVariants;
  onSelect?: (p: ProductVariant | undefined) => void;
}) {
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant | undefined>();

  useEffect(() => {
    setSelectedVariant(undefined);
  }, [product]);

  const handleVariantChange = (v: ProductVariant) => (_: SyntheticEvent) => {
    if (!onSelect) {
      return;
    }
    if (selectedVariant?.id === v.id) {
      setSelectedVariant(undefined);
      onSelect(undefined);
    } else {
      setSelectedVariant(v);
      onSelect(v);
    }
  };

  return (
    <>
      <Box px={2}>
        <Typography variant="h6" gutterBottom>
          Sizes
        </Typography>
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {onSelect && <TableCell padding="checkbox" />}
              <TableCell>SKU</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>MSRP</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>AV Inv</TableCell>
              <TableCell>Ven Inv</TableCell>
              <Can permission="write:inventory_adjustments">
                <TableCell />
              </Can>
            </TableRow>
          </TableHead>
          <TableBody>
            {product.variants.map((v) => (
              <TableRow key={v.id}>
                {onSelect && (
                  <TableCell padding="checkbox">
                    <Radio
                      checked={selectedVariant?.id === v.id}
                      onClick={handleVariantChange(v)}
                    />
                  </TableCell>
                )}
                <TableCell>{v.sku}</TableCell>
                <TableCell>{v.size}</TableCell>
                <TableCell>{curr(v.price)}</TableCell>
                <TableCell>{curr(v.cost)}</TableCell>
                <TableCell>{formatNumber(v.inventory_qty || 0, 'number')}</TableCell>
                <TableCell>{formatNumber(v.vendor_inventory_qty || 0, 'number')}</TableCell>
                <Can permission="write:inventory_adjustments">
                  <TableCell>
                    <Tooltip title="Manage Inventory">
                      <IconButton size="small" component={Link} to={`/inventory-variants/${v.id}`}>
                        <Assignment fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </Can>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
