import { ChangeEvent, SyntheticEvent, useState } from 'react';
import {
  List,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItem,
  Avatar,
  TablePagination,
  Chip,
  CardContent,
  ListItemButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import OrderStatus from '@/components/Orders/OrderStatus';
import { ReportTypeProps } from '@/local-types';
import { ReportResultsRow } from '@/types';
import formatNumber from '@/utils/formatNumber';
import { maybeAddQueryParams } from '@/utils/query';

const getSecondaryAction = (row: ReportResultsRow) => {
  if ('status' in row && typeof row.status === 'string') {
    return <OrderStatus status={row.status} />;
  }
  if ('curr' in row && typeof row.curr === 'number') {
    return <Chip style={{ padding: '2px 8px' }} label={formatNumber(row.curr, 'curr')} />;
  }
  return null;
};

function ReportListItem({ row }: { row: ReportResultsRow }) {
  const inner = (
    <>
      {row.image && typeof row.image === 'string' && (
        <ListItemAvatar>
          <Avatar src={maybeAddQueryParams(row.image, { w: 200 })} alt="" />
        </ListItemAvatar>
      )}
      <ListItemText primary={row.primary} secondary={row.secondary} />

      {getSecondaryAction(row) && (
        <ListItemSecondaryAction>{getSecondaryAction(row)}</ListItemSecondaryAction>
      )}
    </>
  );

  if (row.href && typeof row.href === 'string') {
    return (
      <ListItemButton component={Link} to={row.href}>
        {inner}
      </ListItemButton>
    );
  }

  return <ListItem>{inner}</ListItem>;
}

export default function ReportList({ results }: ReportTypeProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: SyntheticEvent | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const start = page * rowsPerPage;
  const resultsToShow = results.slice(start, start + rowsPerPage);

  return (
    <div>
      <List dense>
        {resultsToShow.map((r) => (
          <ReportListItem row={r} key={r.id || JSON.stringify(r)} />
        ))}
      </List>
      <CardContent>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={results.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </div>
  );
}
