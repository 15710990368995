import { useState } from 'react';
import { CheckCircle, History, List as ListIcon } from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardHeader,
  CircularProgress,
  Grid2 as Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { useCreatePayout, useGetPendingPayouts } from '@/api/payouts';
import { LinkAction } from '@/classes';
import ActionsRenderer from '@/components/Actions/ActionsRenderer';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import { useTitle } from '@/contexts/AppContext';
import { useDialogs } from '@/contexts/DialogContext';
import { PendingPayout } from '@/local-types';
import curr from '@/utils/curr';
import getBusinessName from '@/utils/getBusinessName';
import { maybeAddQueryParams } from '@/utils/query';
import BalanceDetailsDrawer from './BalanceDetailsDrawer';
import PayoutHistoryDrawer from './PayoutHistoryDrawer';

export default function Payouts() {
  useTitle('Payouts', undefined, [
    <ActionsRenderer
      actions={[new LinkAction('Payout History', '/business-payouts').withIcon(History)]}
    />,
  ]);

  const { confirm } = useDialogs();
  const [drawer, setDrawer] = useState<{ type: string; business: PendingPayout } | null>(null);

  const { data: businesses = [], isLoading, isFetching } = useGetPendingPayouts();

  const createRequest = useCreatePayout();

  const onApprovePayout = (b: PendingPayout) => {
    confirm({
      title: 'Confirm Payout',
      description: (
        <div>
          Are you sure you want to approve a payout in the amount of <b>{curr(b.balance)}</b> for{' '}
          <i>{getBusinessName(b)}</i>? A payable will be created for this business in this amount.
        </div>
      ),
      color: 'success',
    }).then(() => {
      createRequest.mutate(b.id);
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div>
      {(isFetching || createRequest.isLoading) && <LinearProgress />}
      <Grid container spacing={3}>
        {[false, true].map((isHouse) => (
          <Grid key={isHouse ? 'internal' : 'external'} size={{ xs: 12, md: 6 }}>
            <Card>
              <CardHeader title={isHouse ? 'Internal LOBs' : 'External LOBs'} />
              <List>
                {businesses
                  .filter((b) => b.is_house === isHouse)
                  .map((b) => {
                    const iconOrLogo = b.icon || b.logo;
                    return (
                      <ListItem key={b.id}>
                        <ListItemAvatar>
                          {iconOrLogo && (
                            <Link to={`/businesses/${b.id}`}>
                              <Avatar
                                src={maybeAddQueryParams(iconOrLogo, {
                                  w: 200,
                                  h: 200,
                                  fit: 'contain',
                                  bg: 'white',
                                })}
                              />
                            </Link>
                          )}
                        </ListItemAvatar>
                        <ListItemText
                          primary={getBusinessName(b)}
                          secondary={
                            <div>
                              <span>Balance: </span>
                              <ColoredCurrency amount={b.balance} />
                            </div>
                          }
                        />
                        <ListItemSecondaryAction>
                          {(b.balance !== 0 || b.commissions_sum !== 0) && (
                            <Tooltip title="Approve Payout">
                              <IconButton onClick={() => onApprovePayout(b)} size="large">
                                <CheckCircle style={{ color: green[500] }} />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="View Payout History">
                            <IconButton
                              onClick={() =>
                                setDrawer({
                                  type: 'history',
                                  business: b,
                                })
                              }
                              size="large"
                            >
                              <History />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="View Balance Details">
                            <IconButton
                              onClick={() =>
                                setDrawer({
                                  type: 'details',
                                  business: b,
                                })
                              }
                              size="large"
                            >
                              <ListIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
              </List>
            </Card>
          </Grid>
        ))}

        <PayoutHistoryDrawer
          open={drawer?.type === 'history'}
          onClose={() => setDrawer(null)}
          business={drawer?.business}
        />

        <BalanceDetailsDrawer
          open={drawer?.type === 'details'}
          onClose={() => setDrawer(null)}
          business={drawer?.business}
        />
      </Grid>
    </div>
  );
}
