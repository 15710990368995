import { CSSProperties, ReactNode, SyntheticEvent } from 'react';
import { styled, SxProps } from '@mui/material';

const StyledButton = styled('button')(({ theme }) => ({
  outline: 'none',
  background: 'none',
  border: 'none',
  borderBottom: `1px dashed ${theme.palette.text.secondary}`,
  fontSize: 'inherit',
  color: 'inherit',
  fontFamily: 'Roboto',
  padding: 0,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: 1.25,
}));

export default function TextButton({
  disabled,
  onClick,
  children,
  style = {},
  sx = {},
}: {
  disabled?: boolean;
  children: ReactNode;
  onClick?: (e: SyntheticEvent) => void;
  style?: CSSProperties;
  sx?: SxProps;
}) {
  if (disabled || !onClick) {
    return <span>{children}</span>;
  }
  return (
    <StyledButton
      type="button"
      onClick={onClick}
      style={style}
      sx={sx}
      title={typeof children === 'string' ? children : undefined}
    >
      {children}
    </StyledButton>
  );
}
