import { useCallback } from 'react';
import { Alert } from '@mui/material';
import { useCreateShipment } from '@/api/shipping';
import { FieldFactory } from '@/classes';
import ConditionallyRenderField from '@/components/Shared/ConditionallyRenderField';
import { useConfig } from '@/contexts/AppConfigContext';
import { useDialogs } from '@/contexts/DialogContext';
import { ShipmentBaseExtendedPayload } from '@/local-types';
import {
  Shipment,
  ShipmentCreatePayload,
  shipmentCreatePayloadSchema,
  ShippingMode,
} from '@/types';
import numString from '@/utils/numString';
import { isInventory, isKitting } from '@/utils/shipping';

export default function useMarkAsShipped({
  title,
  onSuccess,
  shippingMode = 'bulk',
}: {
  title: string;
  onSuccess: (s: Shipment) => void;
  shippingMode?: ShippingMode;
}) {
  const { carriers } = useConfig();
  const { prompt, confirm } = useDialogs();
  const createShipmentRequest = useCreateShipment();

  const markAsShipped = useCallback(
    (payload: ShipmentBaseExtendedPayload, qty: number) => {
      const onSubmit = (v: Partial<ShipmentCreatePayload> = {}) =>
        createShipmentRequest
          .mutateAsync({
            ...payload,
            ...v,
          })
          .then(onSuccess);

      const itemsString = isKitting(shippingMode) ? 'kits' : 'items';
      if (isInventory(shippingMode)) {
        if (payload.items == null) {
          throw new Error('Expected items to be set on the payload when shipping inventory.');
        }
        const someSplit =
          shippingMode === 'inventory' &&
          payload.items.some(
            (i) => i.inventory_unit_conversion != null && i.inventory_unit_conversion > 1,
          );
        const someCombine =
          shippingMode === 'inventory' &&
          payload.items.some(
            (i) => i.inventory_unit_conversion != null && i.inventory_unit_conversion < 1,
          );
        const convertedQty = payload.items.reduce((agg, i) => {
          const qty = Math.floor((i.qty_shipped ?? 1) * (i.inventory_unit_conversion ?? 1));
          return agg + qty;
        }, 0);
        return confirm({
          title,
          description: (
            <div>
              <p>
                This will mark {numString(qty, itemsString)} as shipped and add{' '}
                {convertedQty !== qty ? numString(convertedQty, itemsString) : 'them'} to the
                default inventory location. Are you sure you want to proceed?
              </p>
              {(someSplit || someCombine) && (
                <Alert severity="info">
                  Please note: Some items have a unit conversion. This means that you will need to{' '}
                  {[someSplit && 'split', someCombine && 'combine'].filter(Boolean).join(' and ')}{' '}
                  the items before putting them in inventory. Please refer to the "Inventory Unit
                  Conversion" column to see which items are affected.
                </Alert>
              )}
            </div>
          ),
        }).then(() => onSubmit());
      }

      return prompt({
        title,
        description: `You will be marking ${numString(qty, itemsString)} as shipped. You can optionally add tracking information if you have it.`,
        fields: [
          FieldFactory.select('carrier', carriers),
          new ConditionallyRenderField(
            'tracking_number',
            FieldFactory.text('tracking_number'),
            (v) => v.carrier !== 'courier',
          ),
          FieldFactory.curr('cost').withHelp('Only add if shipping cost is not on a PO'),
          FieldFactory.file('file'),
        ],
        schema: shipmentCreatePayloadSchema.pick({
          carrier: true,
          tracking_number: true,
          cost: true,
          file: true,
        }),
        onSubmit: (v) => onSubmit(v),
      });
    },
    [carriers, createShipmentRequest.mutateAsync, prompt, confirm, title, shippingMode],
  );

  return {
    markAsShipped,
    isLoading: createShipmentRequest.isLoading,
    error: createShipmentRequest.error,
  };
}
