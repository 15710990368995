import { useCallback } from 'react';
import {
  Box,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid2 as Grid,
  TablePagination,
  useMediaQuery,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import type { FieldValues } from 'react-hook-form';
import { JsonParam, NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { ProductWithVariants } from '@/local-types';
import { ProductSearchResponse } from '@/types';
import ProductSearchField from './ProductSearchField';
import ProductSearchFilters from './ProductSearchFilters';
import ProductTable from './ProductTable';

const FILTERS = {
  in_inventory: 'In Avail Inventory',
  in_vendor_inventory: 'In Vendor Inventory',
  is_quick_ship: 'Quick Ship',
};

export default function ProductSearchGrid({
  onSelect,
  customerId,
  defaultFacets = {},
}: {
  onSelect: (v: ProductWithVariants) => void;
  customerId?: number | null;
  defaultFacets?: Record<string, string>;
}) {
  const isMobile = useMediaQuery('(max-width: 1000px)');

  const [query, setQuery] = useQueryParam('query', withDefault(StringParam, ''));
  const [perPage, setPerPage] = useQueryParam('count', withDefault(NumberParam, 10));
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0));
  const [facets, setFacets] = useQueryParam('facets', withDefault(JsonParam, defaultFacets));
  const [filters, setFilters] = useQueryParam('filters', withDefault(JsonParam, {}));

  const { data, isLoading } = useQuery(
    ['products', page, perPage, query, JSON.stringify(facets), JSON.stringify(filters)],
    () => {
      const params: Record<string, string | boolean | number> = {
        query,
        count: perPage,
        ...facets,
        ...pickBy(filters),
        with: 'variants.product,images',
        page: page + 1,
      };
      if (customerId) {
        params['customer_id'] = customerId;
      }
      return axios.get<ProductSearchResponse>('/api/products', { params }).then(({ data }) => data);
    },
  );

  const total = data?.meta?.total;

  const onFacetChange = useCallback(
    (facet: string, label: string) => {
      setFacets((prev: FieldValues) => {
        if (prev[facet] === label) {
          return omit(prev, facet);
        }
        return {
          ...prev,
          [facet]: label,
        };
      });
    },
    [setFacets],
  );
  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 3 }}>
        <Box p={2}>
          <ProductSearchField initialValue={query} onChange={setQuery} />

          <FormGroup>
            {Object.entries(FILTERS).map(([filter, label]) => (
              <FormControlLabel
                key={filter}
                control={
                  <Checkbox
                    size="small"
                    checked={filters[filter]}
                    onChange={(e) =>
                      setFilters((prev: Record<string, boolean>) => ({
                        ...prev,
                        [filter]: e.target.checked,
                      }))
                    }
                  />
                }
                label={label}
              />
            ))}
          </FormGroup>

          {!isMobile && data && (
            <ProductSearchFilters results={data} facets={facets} onFacetChange={onFacetChange} />
          )}
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 9 }}>
        {isLoading && (
          <CardContent>
            <CircularProgress />
          </CardContent>
        )}
        {data && <ProductTable products={data.data} onSelect={onSelect} />}

        {total != null && (
          <TablePagination
            component="div"
            page={page}
            rowsPerPage={perPage}
            count={total}
            onPageChange={(e, value) => setPage(value)}
            onRowsPerPageChange={(e) => setPerPage(Number(e.target.value))}
          />
        )}
      </Grid>
    </Grid>
  );
}
