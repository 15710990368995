import { InputHTMLAttributes } from 'react';
import { ShipmentItemExtendedPayload } from '@/local-types';
import { OrderItem, ShippingMode } from '@/types';
import { abscomp } from '@/utils/helpers';

export const orderItemToShipmentItem = (i: OrderItem): ShipmentItemExtendedPayload => ({
  qty_shipped: i.qty_to_ship,
  order_item_id: i.id,
  inventory_unit_conversion: i.inventory_unit_conversion,
});

export const canShipOrderItem = (i: OrderItem) =>
  abscomp(i.qty_shipped, '<', i.qty - i.qty_scrapped);

export const isInventory = (mode: ShippingMode): boolean =>
  ['inventory', 'inventory_kit'].includes(mode);

export const isKitting = (mode: ShippingMode): boolean => ['kits', 'inventory_kit'].includes(mode);

export const getQtyInputProps = (
  remaining: number,
  total: number,
): InputHTMLAttributes<HTMLInputElement> => ({
  step: 1,
  min: total >= 0 ? 0 : remaining,
  max: total >= 0 ? remaining : 0,
  disabled: remaining === 0,
});

export function getIdFromIncrementId(incrementId: string) {
  return Number(incrementId.split('-')[1]);
}
