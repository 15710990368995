import { useCallback, useEffect } from 'react';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useToast } from '@/contexts/DialogContext';
import { KitWithAddress } from '@/local-types';
import { Kit, KitItem, KitPayload, Order, OrderItem, Packout } from '@/types';
import { PartiallyRequired } from '@/types';
import { makeResourceQueryKey, useRecordId } from '@/utils/genericResource';

export function useGetOrderPackouts(orderId: number) {
  return useQuery(makeResourceQueryKey('orders', orderId, 'packouts'), () =>
    axios
      .get<{
        data: PartiallyRequired<Packout, 'timing'>[];
      }>(`/api/order-packouts?filter[order_id]=${orderId}&with=timing`)
      .then(({ data }) => data.data),
  );
}

export function useGetKitItemsForPackout(orderId: number) {
  return useQuery(['packoutKitItems', orderId], () =>
    axios
      .get<{ data: Required<KitItem>[] }>(`/api/kit-items?order_id=${orderId}`)
      .then(({ data }) => data.data),
  );
}

export function handleKitItemPicked({
  queryClient,
  kitItem,
  orderId,
  skipResetPageIndex,
}: {
  queryClient: QueryClient;
  kitItem: KitItem;
  orderId: number;
  skipResetPageIndex: () => void;
}) {
  skipResetPageIndex();
  queryClient.setQueryData<Required<KitItem>[]>(['packoutKitItems', orderId], (prev) => {
    if (!prev) return prev;
    return prev.map((i) => (i.id === kitItem.id ? { ...i, ...kitItem } : i));
  });
}

export function useWatchKitItems(orderId: number, skipResetPageIndex: () => void) {
  const queryClient = useQueryClient();

  useEffect(() => {
    const channel = window.Echo.private(`packouts.${orderId}`);

    channel.listen('KitItemPicked', (e: { kit_item: KitItem }) => {
      handleKitItemPicked({
        queryClient,
        kitItem: e.kit_item,
        orderId,
        skipResetPageIndex,
      });
    });

    return () => {
      channel.stopListening('KitItemPicked');
      window.Echo.leave(`packouts.${orderId}`);
    };
  }, [orderId, queryClient]);
}

export function usePickKitItem(orderId: number, skipResetPageIndex: () => void) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, ...other }: { id: number; picked_qty: number }) =>
      axios.post<Required<KitItem>>(`/api/kit-items/${id}/pick`, other).then(({ data }) => data),
    {
      onSuccess: (kitItem) => {
        handleKitItemPicked({
          queryClient,
          kitItem,
          orderId,
          skipResetPageIndex,
        });
      },
    },
  );
}

export function useGetKitsForOrder(orderId: number) {
  return useQuery(['kitsForOrder', orderId], () =>
    axios
      .get<{ data: PartiallyRequired<Kit, 'address'>[] }>(`/api/kits?order_id=${orderId}`)
      .then(({ data }) => data.data),
  );
}

export function usePerformKitAction(orderId: number, skipResetPageIndex: () => void) {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(({ id, action }: { id: number; action: string }) =>
    axios.post<KitWithAddress>(`/api/kits/${id}/${action}`).then(({ data }) => {
      skipResetPageIndex();
      queryClient.setQueryData<KitWithAddress[]>(['kitsForOrder', orderId], (prev) => {
        if (!prev) return prev;
        return prev.map((p) => {
          if (p.id === data.id) {
            return data;
          }
          return p;
        });
      });

      toast(`Kit: ${data.number || data.name} is now ${data.pick_status}`);
    }),
  );
}

export function useKitProductionOrders(orderId: number) {
  return useQuery(['kitProductionOrders', orderId], () =>
    axios
      .get<{
        data: Omit<PartiallyRequired<Order, 'shippable_items'>, 'items'>[];
      }>(`/api/orders/${orderId}/production-orders`)
      .then(({ data }) => data.data),
  );
}

function useOnKitItemUpdate() {
  const queryClient = useQueryClient();
  const orderId = Number(useRecordId());

  return useCallback(() => {
    // Refreshes the production order items (qty_assigned)
    queryClient.invalidateQueries(['kitProductionOrders']);

    // Refreshes list of kits and their items once you click on address
    queryClient.invalidateQueries(['kitsForAddress']);

    // Refreshes misconfigured status
    queryClient.invalidateQueries(makeResourceQueryKey('orders', orderId, 'packouts'));
  }, [queryClient]);
}

export function useKitsForAddress(addressId: number) {
  return useQuery(['kitsForAddress', addressId], () =>
    axios
      .get<{
        data: PartiallyRequired<Kit, 'items'>[];
      }>(`/api/kits?address_id=${addressId}&include_backordered=1`)
      .then(({ data }) => data.data),
  );
}

export function useCreateKitItem(item: OrderItem) {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((payload: { kit_id: number; qty: number; drops: string[] }) =>
    axios
      .post<KitItem>('/api/kit-items', {
        order_item_id: item.id,
        ...payload,
      })
      .then(({ data }) => {
        onKitItemUpdate();
        return data;
      }),
  );
}

export function useBulkCreateKitItems(orderId: number, item: OrderItem) {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((v: { qty: number; address_id?: number }) =>
    axios
      .post('/api/kit-items/bulk-add', {
        order_id: orderId,
        order_item_id: item.id,
        ...v,
      })
      .then(() => {
        onKitItemUpdate();
      }),
  );
}

export function useSwitchItem(orderId: number, item: OrderItem) {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((toId: number) =>
    axios
      .post('/api/kit-items/switch-item', {
        order_id: orderId,
        from_id: item.id,
        to_id: toId,
      })
      .then(() => {
        onKitItemUpdate();
      }),
  );
}

export function useCreateKit(addressId: number) {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((v: KitPayload) =>
    axios.post<KitWithAddress>('/api/kits', { address_id: addressId, ...v }).then(({ data }) => {
      onKitItemUpdate();
      return data;
    }),
  );
}

export function useBulkCreateKits(addressId: number) {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((v: { quantity: number }) =>
    axios
      .post<{ data: KitWithAddress[] }>('/api/kits/bulk-create', { address_id: addressId, ...v })
      .then(({ data }) => {
        onKitItemUpdate();
        return data;
      }),
  );
}

export function useUpdateKit() {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation(({ id, ...v }: KitPayload & { id: number }) =>
    axios.put<KitWithAddress>(`/api/kits/${id}`, v).then(({ data }) => {
      onKitItemUpdate();
      return data;
    }),
  );
}

export function useDeleteKit() {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((id: number) =>
    axios.delete(`/api/kits/${id}`).then(() => {
      onKitItemUpdate();
    }),
  );
}

export function useUpdateKitItem() {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation(
    ({ id, ...v }: { id: number; is_backordered: boolean; qty: number; drops: string[] }) =>
      axios.put<KitItem>(`/api/kit-items/${id}`, v).then(({ data }) => {
        onKitItemUpdate();
        return data;
      }),
  );
}

export function useDeleteKitItem() {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((id: number) =>
    axios.delete(`/api/kit-items/${id}`).then(() => {
      onKitItemUpdate();
    }),
  );
}
