import { ArrowForward } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import omit from 'lodash/omit';
import { z } from 'zod';
import { useMarkSent, useMarkUnsent, usePrintChecks, useSendAchPayment } from '@/api/transactions';
import FieldFactory from '@/classes/FieldFactory';
import Can from '@/components/Permissions/Can';
import ConditionallyRenderField from '@/components/Shared/ConditionallyRenderField';
import StatusChip from '@/components/Shared/StatusChip';
import { VENDOR_PAYMENT_METHODS } from '@/constants';
import { useDialogs } from '@/contexts/DialogContext';
import { vendorPaymentMethodSchema, zodNullableNumber } from '@/types';
import curr from '@/utils/curr';
import { formatDate } from '@/utils/dates';
import { useRecord } from '@/utils/genericResource';

export default function PaymentSentStatusCard() {
  const transaction = useRecord('transactions');
  const { prompt, confirm } = useDialogs();

  const markSentRequest = useMarkSent(transaction.id);
  const markUnsentRequest = useMarkUnsent(transaction.id);
  const printRequest = usePrintChecks();
  const sendAchPaymentRequest = useSendAchPayment(transaction.id);

  const onPaid = () => {
    prompt({
      title: 'Mark as Paid',
      fields: [
        FieldFactory.select('payment_method', VENDOR_PAYMENT_METHODS),
        new ConditionallyRenderField(
          'check_number',
          FieldFactory.number('check_number').setRequired(),
          (values) => values.payment_method === 'check',
        ),
      ],
      schema: z.object({
        payment_method: vendorPaymentMethodSchema,
        check_number: zodNullableNumber(z.number().int().positive().nullish()),
      }),
      initialValues: {
        payment_method:
          transaction.payment_method || transaction.vendor?.default_payment_method || 'credit_card',
        check_number: transaction.bank_account?.next_check_number || 1,
      },
      onSubmit: (values) => {
        const payload: typeof values = omit(values, 'check_number');
        if (values.payment_method === 'check') {
          payload.check_number = values.check_number;
        }
        return markSentRequest.mutateAsync(payload);
      },
    });
  };

  const onPrint = () => {
    prompt({
      title: 'Print Check',
      fields: [FieldFactory.number('starting_check_number').setRequired()],
      schema: z.object({
        starting_check_number: z.number().int().positive(),
      }),
      initialValues: {
        starting_check_number: transaction.bank_account!.next_check_number || 1000,
      },
      onSubmit: (values) =>
        printRequest.mutateAsync({
          payment_method: 'check',
          bank_account_id: transaction.bank_account!.id,
          transaction_ids: [transaction.id],
          ...values,
        }),
    });
  };

  const onAch = () => {
    confirm({
      title: 'Send ACH Payment',
      description:
        "Are you sure you want to send an ACH payment? We will send an email to the vendor to collect their bank information if we don't already have it, and then use the Mercury API to send the payment.",
    }).then(() => {
      sendAchPaymentRequest.mutate();
    });
  };

  return (
    <Card>
      <CardHeader title="Payment Status" />

      <CardContent>
        <StatusChip
          status={transaction.payment_sent_at ? 'payment_sent' : 'payment_not_sent'}
          style={{ display: 'flex' }}
          colors={{
            payment_sent: 'green',
            payment_not_sent: 'gray',
          }}
        />
        <Can permission="write:transactions">
          <Box mt={2} display="flex" gap={1} justifyContent="center" flexWrap="wrap">
            {transaction.payment_sent_at ? (
              <LoadingButton
                onClick={() => markUnsentRequest.mutate()}
                loading={markUnsentRequest.isLoading}
              >
                Mark Not Sent
              </LoadingButton>
            ) : (
              <>
                {transaction.bank_account?.has_mercury_id && (
                  <LoadingButton
                    onClick={onAch}
                    variant="contained"
                    loading={sendAchPaymentRequest.isLoading}
                    size="small"
                  >
                    Send ACH
                  </LoadingButton>
                )}

                <Button onClick={onPrint} variant="outlined" size="small">
                  Print Check
                </Button>

                <Button onClick={onPaid} size="small">
                  Mark Sent
                </Button>
              </>
            )}
          </Box>
        </Can>
      </CardContent>
      {transaction.ach_payments && transaction.ach_payments.length > 0 && (
        <List>
          {transaction.ach_payments.map((achPayment) => (
            <ListItem key={achPayment.id}>
              <ListItemText
                primary={`ACH sent on ${formatDate(achPayment.created_at)}`}
                secondary={curr(achPayment.amount)}
              />
              <StatusChip
                status={achPayment.status}
                colors={{
                  pending: 'gray',
                  sent: 'blue',
                  success: 'green',
                  failed: 'red',
                }}
              />
              {achPayment.url && (
                <IconButton component="a" href={achPayment.url} target="_blank">
                  <ArrowForward />
                </IconButton>
              )}
            </ListItem>
          ))}
        </List>
      )}
    </Card>
  );
}
