import { CreditCard, Email } from '@mui/icons-material';
import axios from 'axios';
import pick from 'lodash/pick';
import { z } from 'zod';
import { Resource, Action, LinkAction, FieldFactory, ButtonAction, CardLayout } from '@/classes';
import { OnClickProps } from '@/classes/types';
import { getLedgerEntriesAction } from '@/components/Accounting/ModelLedgerEntries';
import PaymentApplication from '@/components/Payments/PaymentApplication';
import PaymentDepositDetails from '@/components/Payments/PaymentDepositDetails';
import StripePaymentDetails from '@/components/Payments/StripePaymentDetails';
import ArrayBullets from '@/components/Text/ArrayBullets';
import UserLabel from '@/components/Users/UserLabel';
import {
  APPLIED_STATUS_COLORS,
  APPLIED_STATUS_LABELS,
  PAYMENT_METHODS,
  PAYMENT_TYPES,
} from '@/constants';
import { Deposit, Payment, paymentPayloadSchema } from '@/types';
import curr from '@/utils/curr';
import { getEventableConfigForResource } from '@/utils/resources';

export default function payments(): Resource<Payment> {
  return new Resource<Payment>('Payments')
    .withSchema(paymentPayloadSchema)
    .getTitleUsing((p) => p.label)
    .getLabelUsing((p) => p.label)
    .getSubtitleUsing((p) => <ArrayBullets elements={[p.payment_date, curr(p.amount)]} />)
    .withDefaultSort('-id')
    .withDefaultValues({
      payment_type: 'payment',
      payment_date: new Date().toISOString().slice(0, 10),
    })
    .setCanExport()
    .withQueryParams({
      single: {
        with: 'stripe_metadata',
      },
    })
    .withIndexActions([new LinkAction('POS', '/pos').withIcon(CreditCard)])
    .getSingleActionsUsing((values) => {
      const actions: Action[] = [];

      const onRefund = ({ dialogs, toast, navigate }: OnClickProps) => {
        dialogs
          .prompt({
            title: 'Refund Card',
            description:
              'This will create a new negative payment. The customer will see the credit on their statement in 5-10 business days.',
            fields: [FieldFactory.curr('amount').withLabel('Amount to Refund').withSize('medium')],
            schema: z.object({
              amount: z.coerce.number().min(0.01),
            }),
            initialValues: pick(values, 'amount'),
            onSubmit: (v) => axios.post(`/api/payments/${values.id}/refund`, v),
          })
          .then(({ data }) => {
            toast('Refund created successfully');
            navigate(`/payments/${data.id}`);
          });
      };

      const sourceId = String(values.source_id);
      if (sourceId.startsWith('ch_') || sourceId.startsWith('py_')) {
        actions.push(
          new ButtonAction('Refund', onRefund)
            .withIcon(CreditCard)
            .withPermission('write:payments'),
        );
      }

      actions.push(
        getLedgerEntriesAction('payments', values.id),
        new ButtonAction('Send Receipt', ({ dialogs }) => {
          dialogs.prompt({
            title: 'Send Receipt To Email',
            fields: [FieldFactory.email('email').withSize('medium')],
            initialValues: {
              email: values.email || '',
            },
            schema: z.object({
              email: z.string().email(),
            }),
            onSubmit: (v: { email: string }) =>
              axios.post(`/api/payments/${values.id}/send-receipt`, v),
          });
        }).withIcon(Email),
      );

      return actions;
    })
    .withInitialColumns([
      'id',
      'payment_type',
      'payment_date',
      'method',
      'amount',
      'payment_number',
      'customer',
      'applied_status',
    ])
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.timestamp('created_at'),
      FieldFactory.curr('amount_to_apply').sortable().setAggregatable(),
      FieldFactory.curr('amount_applied').sortable().setAggregatable(),
    ])
    .addFieldsAndColumns([
      new CardLayout('', [
        FieldFactory.select('payment_type', PAYMENT_TYPES).setRequired(true).filterable(),
        FieldFactory.date('payment_date').setRequired().filterable().sortable(),
        FieldFactory.select('method', PAYMENT_METHODS).setRequired().filterable().sortable(),
        FieldFactory.curr('amount').setRequired().sortable().setAggregatable(),
        FieldFactory.text('payment_number').setRequired().sortable(),
        FieldFactory.belongsTo('customer', 'customers').filterable('filter[customer_id]'),
        FieldFactory.textarea('memo'),
        FieldFactory.custom('deposit', PaymentDepositDetails)
          .renderCellUsing((d: Deposit | null) => (d ? `Deposit #${d.id}` : null))
          .setCreatable(false),
        FieldFactory.status('applied_status', APPLIED_STATUS_COLORS, APPLIED_STATUS_LABELS)
          .filterable()
          .sortable()
          .setCreatable(false),
      ]),
      new CardLayout('Card Details', [
        FieldFactory.text('card_name').withLabel('Customer Name'),
        FieldFactory.text('email').withLabel('Customer Email'),
        FieldFactory.text('card_brand').withLabel('Card Brand'),
        FieldFactory.text('card_last_four').withLabel('Card Last Four'),
        FieldFactory.text('card_exp_date').withLabel('Card Exp Date'),
        FieldFactory.custom('stripe_metadata', StripePaymentDetails).withColumnSpan(12),
      ]),
      new CardLayout('Payment Application')
        .withoutPadding()
        .withoutDefaultLayout()
        .withFields([FieldFactory.custom('order_payments', PaymentApplication)])
        .setCreatable(false),
      getEventableConfigForResource('payments'),
      new CardLayout('Advanced', [
        FieldFactory.curr('discount').sortable(),
        FieldFactory.belongsTo('business', 'businesses'),
        FieldFactory.curr('processing_fee').setReadOnly().sortable().setCreatable(false),
        FieldFactory.belongsTo('created_by_user', 'users')
          .setCreatable(false)
          .setReadOnly()
          .renderCellUsing((u) => <UserLabel user={u} />),
        FieldFactory.belongsTo('debit_account', 'accounts').withLabel('Override Account to Debit'),
      ]),
    ])
    .withFilters([
      FieldFactory.boolean('is_refund').withLabel('Is Refund').withFilterKey('is_refund'),
    ]);
}
