import { useEffect } from 'react';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ProductionEvent, ProductionEventUpdatePayload, ProductionMachineDetail } from '@/types';
import { makeResourceQueryKey, useRecordId } from '@/utils/genericResource';

type ProductionEventPayload = Pick<ProductionEvent, 'id' | 'order_id' | 'status'>;

function getQueryKey(orderId: number) {
  return makeResourceQueryKey('orders', orderId, 'productionEvents');
}

function handleUpdatedEvent(queryClient: QueryClient, event: ProductionEventPayload) {
  queryClient.setQueryData<ProductionEvent[]>(getQueryKey(event.order_id), (prev) => {
    if (!prev || prev.length === 0) {
      return prev;
    }
    return prev.map((ev) => {
      if (ev.id === event.id) {
        return { ...ev, ...event };
      }
      return ev;
    });
  });

  queryClient.invalidateQueries(['productionEvents']);
}

export function useWatchForProductionEventChanges() {
  const queryClient = useQueryClient();

  useEffect(() => {
    const channel = window.Echo.private('production');

    channel.listen('ProductionEventStatusChanged', (e: { event: ProductionEventPayload }) => {
      handleUpdatedEvent(queryClient, e.event);
    });

    return () => {
      channel.stopListening('ProductionEventStatusChanged');
      window.Echo.leave('production');
    };
  }, [queryClient]);
}

export function useGetProductionEvents(params: Record<string, string>, enabled: boolean) {
  return useQuery(
    ['productionEvents', JSON.stringify(params)],
    () =>
      axios
        .get<{ data: ProductionEvent[] }>('/api/production-events', { params })
        .then(({ data }) => data),
    {
      enabled,
    },
  );
}

export function useGetProductionEventsForOrder(orderId: number) {
  useWatchForProductionEventChanges();

  return useQuery(getQueryKey(orderId), () =>
    axios
      .get<{
        production_events: ProductionEvent[];
      }>(`/api/orders/${orderId}?with=production_events`)
      .then(({ data }) => data.production_events),
  );
}

export function useProductionEventAction() {
  const queryClient = useQueryClient();
  return useMutation(({ eventId, action }: { eventId: number; action: string }) =>
    axios
      .post<ProductionEvent>(`/api/production-events/${eventId}/action/${action}`)
      .then(({ data }) => {
        handleUpdatedEvent(queryClient, data);
      }),
  );
}

export function useResetProductionEvents(orderId: number) {
  const queryClient = useQueryClient();
  return useMutation(() =>
    axios.post(`/api/orders/${orderId}/reset-production`).then(() => {
      queryClient.invalidateQueries(getQueryKey(orderId));
    }),
  );
}

export function useUpdateProductionEvent() {
  const queryClient = useQueryClient();

  return useMutation((ev: ProductionEventUpdatePayload & { id: number }) =>
    axios.put<ProductionEvent>(`/api/production-events/${ev.id}`, ev).then(({ data }) => {
      handleUpdatedEvent(queryClient, data);
    }),
  );
}

export function useDeleteProductionEvent(orderId: number) {
  const queryClient = useQueryClient();

  return useMutation((eventId: number) =>
    axios.delete(`/api/production-events/${eventId}`).then(() => {
      queryClient.setQueryData<ProductionEvent[]>(getQueryKey(orderId), (prev) => {
        if (!prev) {
          return prev;
        }
        return prev.filter((ev) => ev.id !== eventId);
      });
    }),
  );
}

export function useDuplicateProductionEvent() {
  const queryClient = useQueryClient();

  return useMutation((eventId: number) =>
    axios.post<ProductionEvent>(`/api/production-events/${eventId}/duplicate`).then(({ data }) => {
      queryClient.setQueryData<ProductionEvent[]>(getQueryKey(data.order_id), (prev) => {
        if (!prev) {
          return prev;
        }
        return [...prev, data];
      });
    }),
  );
}

export function useBurnProductionEvent() {
  const queryClient = useQueryClient();

  return useMutation(({ eventId, ...v }: { eventId: number; screen_location?: string }) =>
    axios.post<ProductionEvent>(`/api/production-events/${eventId}/burn`, v).then(({ data }) => {
      handleUpdatedEvent(queryClient, data);
    }),
  );
}

export function useUnscheduleProductionEvent() {
  const queryClient = useQueryClient();

  return useMutation((eventId: number) =>
    axios.post<ProductionEvent>(`/api/production-events/${eventId}/unschedule`).then(({ data }) => {
      handleUpdatedEvent(queryClient, data);
    }),
  );
}

interface SchedulePayload {
  date: string | undefined;
  machine_id: number | undefined;
  event_ids: number[];
}

export function useScheduleEventsPreview(payload: SchedulePayload) {
  return useQuery(
    ['schedulingReview', JSON.stringify(payload)],
    () =>
      axios
        .post<{
          additional_minutes: number;
          total_minutes: number;
        }>('/api/production-events/review', payload)
        .then(({ data }) => data),
    {
      enabled: Boolean(payload.event_ids.length > 0 && payload.date && payload.machine_id),
    },
  );
}

export function useScheduleEvents() {
  const queryClient = useQueryClient();
  const recordId = useRecordId();

  return useMutation(
    (payload: SchedulePayload) =>
      axios
        .post<{ data: ProductionEvent[] }>('/api/production-events/schedule', payload)
        .then(({ data }) => data.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getQueryKey(Number(recordId)));
        queryClient.invalidateQueries(['dataTable', 'productionEvents']);
        queryClient.invalidateQueries(['productionEvents']);
      },
    },
  );
}

export function useProductionMachinesForScheduling(payload: Omit<SchedulePayload, 'machine_id'>) {
  return useQuery(
    ['eventMachines', JSON.stringify(payload)],
    () =>
      axios
        .get<{ data: ProductionMachineDetail[] }>('/api/production-machines', {
          params: { event_ids: payload.event_ids.join(','), date: payload.date },
        })
        .then(({ data }) => data.data),
    {
      enabled: Boolean(payload.event_ids.length > 0 && payload.date),
    },
  );
}
