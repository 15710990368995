import { ReactNode } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails as AccordionDetailsRaw,
  AccordionSummary,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useGetPendingPayout } from '@/api/payouts';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import { PendingPayout } from '@/local-types';
import curr from '@/utils/curr';
import getBusinessName from '@/utils/getBusinessName';
import { buildIncrementId } from '@/utils/notes';

function AccordionDetails({ children }: { children: ReactNode }) {
  return <AccordionDetailsRaw style={{ display: 'block' }}>{children}</AccordionDetailsRaw>;
}

export default function BalanceDetailsDrawer({
  open,
  onClose,
  business,
}: {
  open: boolean;
  onClose: () => void;
  business?: PendingPayout;
}) {
  const { data, isLoading } = useGetPendingPayout(open && business ? business.id : undefined);

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Payout Details" width={600}>
      {business && (
        <Box mt={-2} mb={3}>
          <Typography variant="subtitle1" gutterBottom color="textSecondary">
            Business: {getBusinessName(business)}
          </Typography>
          <Typography gutterBottom>
            Total: <span style={{ fontWeight: 500 }}>{curr(business.balance)}</span>
          </Typography>
        </Box>
      )}

      {isLoading && <CircularProgress />}
      {data && (
        <div>
          {data.lines.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Orders</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {data.lines.map((l) => (
                    <ListItemButton key={l.id} component={Link} to={`/orders/${l.order_id}`}>
                      <ListItemText
                        primary={buildIncrementId('SO', l.order_id)}
                        secondary={l.customer_name}
                      />
                      <ListItemSecondaryAction>
                        <ColoredCurrency amount={l.amount} />
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}

          {data.credits.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div>
                  <Typography>Business Credits</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Credits for orders that Avail did not complete correctly.
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {data.credits.map((c) => (
                    <ListItem key={c.id}>
                      <ListItemText primary={c.description} />
                      <ListItemSecondaryAction>
                        <ColoredCurrency amount={c.amount} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}

          {data.deductions.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Payout Deductions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {data.deductions.map((op) => (
                    <ListItem key={op.id}>
                      <ListItemText primary={op.payment!.label} />
                      <ListItemSecondaryAction>
                        <ColoredCurrency amount={op.amount_applied} opposite />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}

          {data.purchase_orders.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div>
                  <Typography>Purchase Orders</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Items we purchased from the LOB.
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {data.purchase_orders.map((po) => (
                    <ListItem key={po.id}>
                      <ListItemText primary={po.label} secondary={po.description} />
                      <ListItemSecondaryAction>
                        <ColoredCurrency amount={po.total} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}

          {data.salespeople.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div>
                  <Typography>Commissions Earned</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Just for reference. Not included in payout total.
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {data.salespeople.map((s) => (
                    <ListItem key={s.id}>
                      <ListItemText primary={s.user_name} />
                      <ListItemSecondaryAction>
                        <ColoredCurrency amount={s.amount} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      )}
    </ClosableDrawer>
  );
}
