import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useDialogs } from '@/contexts/DialogContext';
import { Transaction, VendorPaymentMethod } from '@/types';
import { makeResourceQueryKey } from '@/utils/genericResource';

export function useMarkSent(transactionId: number) {
  const queryClient = useQueryClient();

  return useMutation((p: { payment_method: VendorPaymentMethod; check_number?: number | null }) =>
    axios.post<Transaction>(`/api/transactions/${transactionId}/mark-sent`, p).then(({ data }) => {
      queryClient.setQueryData<Transaction>(
        makeResourceQueryKey('transactions', transactionId),
        data,
      );
      return data;
    }),
  );
}

export function useMarkUnsent(transactionId: number) {
  const queryClient = useQueryClient();

  return useMutation(() =>
    axios.post<Transaction>(`/api/transactions/${transactionId}/mark-unsent`).then(({ data }) => {
      queryClient.setQueryData<Transaction>(
        makeResourceQueryKey('transactions', transactionId),
        data,
      );
      return data;
    }),
  );
}

export function useGetChecksToPrint(
  bankAccountId: number | null | undefined,
  paymentMethod: VendorPaymentMethod,
) {
  return useQuery(
    ['checksToPrint', bankAccountId],
    () => {
      const params = {
        to_print: paymentMethod,
        'filter[bank_account_id]': bankAccountId,
        count: 250,
      };
      return axios
        .get<{ data: Transaction[] }>('/api/transactions', { params })
        .then(({ data }) => data.data);
    },
    {
      enabled: Boolean(bankAccountId),
    },
  );
}

export function useMarkPrinted() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: {
      payment_method: VendorPaymentMethod;
      bank_account_id: number;
      transaction_ids: number[];
      starting_check_number?: number;
    }) =>
      axios
        .post<{ data: Transaction[] }>('/api/transactions/mark-checks', payload)
        .then(({ data }) => {
          // Refetch current transaction
          queryClient.invalidateQueries(['genericRecord', 'transactions']);
          // Refetch the list of checks to print
          queryClient.invalidateQueries(['checksToPrint']);
          // There's a new starting check number
          queryClient.invalidateQueries(['bankAccounts']);

          return data.data;
        }),
  );
}

export function usePrintChecks() {
  const { confirm } = useDialogs();
  const markPrinted = useMarkPrinted();

  return useMutation(
    (payload: {
      bank_account_id: number;
      starting_check_number: number;
      transaction_ids: number[];
      payment_method: VendorPaymentMethod;
    }) =>
      axios.post<{ url: string }>('/api/transactions/print-checks', payload).then(({ data }) => {
        window.open(data.url, '_blank');

        return confirm({
          title: 'Confirm Checks Printed',
          description:
            'Did the checks successfully print? This will remove them from the list of checks to print.',
        }).then(() => markPrinted.mutateAsync(payload));
      }),
  );
}

export function useSendAchPayment(transactionId: number) {
  const queryClient = useQueryClient();

  return useMutation(() =>
    axios
      .post<Transaction>(`/api/transactions/${transactionId}/send-ach-payment`)
      .then(({ data }) => {
        queryClient.setQueryData<Transaction>(
          makeResourceQueryKey('transactions', transactionId),
          data,
        );
        return data;
      }),
  );
}
