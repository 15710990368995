import { ForwardedRef, forwardRef, HTMLAttributes, SyntheticEvent } from 'react';
import { styled } from '@mui/material';
import { Link, To } from 'react-router-dom';
import { MappedRow, setOtherRowContext, useContextKey } from '@/hooks/usePushWithContext';

const StyledLink = styled(Link)(({ theme }) => ({
  outline: 'none',
  background: 'none',
  border: 'none',
  borderBottom: `1px dashed ${theme.palette.text.secondary}`,
  fontSize: 'inherit',
  color: 'inherit',
  fontFamily: 'Roboto',
  padding: 0,
  textDecoration: 'none',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: 1.25,
}));

function TextLink(
  {
    disabled,
    children,
    otherRows,
    to,
    ...other
  }: {
    disabled?: boolean;
    otherRows?: MappedRow[];
    to: To;
  } & HTMLAttributes<HTMLAnchorElement>,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const contextKey = useContextKey();

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (otherRows) {
      setOtherRowContext(contextKey, otherRows);
    }
  };

  if (disabled) {
    return <span ref={ref}>{children}</span>;
  }

  return (
    <StyledLink
      {...other}
      to={to}
      state={{ context: contextKey }}
      onClick={handleClick}
      ref={ref}
      title={typeof children === 'string' ? children : undefined}
    >
      {children}
    </StyledLink>
  );
}

export default forwardRef(TextLink);
