import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  Alert,
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  AlertTitle,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { ButtonAction } from '@/classes';
import PurchaseOrderItems from '@/components/Purchasing/PurchaseOrderItems';
import PurchaseOrderTotals from '@/components/Purchasing/PurchaseOrderTotals';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import StatusChip from '@/components/Shared/StatusChip';
import SubMenu from '@/components/Shared/SubMenu';
import Text from '@/components/Text/Text';
import {
  MATCH_STATUS_COLORS,
  MATCH_STATUS_LABELS,
  PURCHASE_ORDER_STATUS_COLORS,
} from '@/constants';
import { useDialogs } from '@/contexts/DialogContext';
import useMutateQueryData from '@/hooks/useMutateQueryData';
import { PurchaseOrderForMatching } from '@/local-types';
import { PurchaseOrder, AccountTransactionItem, BillMatchStatus } from '@/types';
import curr from '@/utils/curr';

function BillItems({ items }: { items: AccountTransactionItem[] }) {
  return (
    <div>
      <Table>
        <TableHead>
          <TableCell>Account</TableCell>
          <TableCell>Amount</TableCell>
        </TableHead>
        <TableBody>
          {items.map((i) => (
            <TableRow key={i.id}>
              <TableCell>
                <Text
                  primary={i.transactable.path}
                  secondary={`#${i.transactable.number} - ${startCase(i.transactable.type)}`}
                />
              </TableCell>
              <TableCell>{curr(i.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default function POMatchingDrawer({
  open,
  onClose,
  purchaseOrderId,
}: {
  open: boolean;
  onClose: () => void;
  purchaseOrderId: number;
}) {
  const { confirm } = useDialogs();
  const location = useLocation();

  const setPurchaseOrder = useMutateQueryData<PurchaseOrderForMatching>([
    'purchaseOrderForMatching',
    purchaseOrderId,
  ]);

  const {
    data: purchaseOrder,
    isLoading,
    refetch,
  } = useQuery(
    ['purchaseOrderForMatching', purchaseOrderId],
    () =>
      axios
        .get<PurchaseOrderForMatching>(
          `/api/purchase-orders/${purchaseOrderId}?with=items,matched_transactions`,
        )
        .then(({ data }) => data),
    {
      enabled: open,
    },
  );

  const onUpdated = (p: Partial<PurchaseOrder>) => {
    setPurchaseOrder(
      (prev) =>
        ({
          ...prev,
          ...p,
        }) as PurchaseOrderForMatching,
    );
    refetch();
  };

  const bills = purchaseOrder?.matched_transactions || [];

  const onAutoMatch = () => {
    axios.post(`/api/purchase-orders/${purchaseOrderId}/auto-match`).then(() => {
      refetch();
    });
  };

  const onStatus = (status: BillMatchStatus) => {
    confirm({
      title: startCase(status),
      description: `Are you sure you want to update the match status on these bills to "${MATCH_STATUS_LABELS[status]}"?`,
    })
      .then(() =>
        Promise.all(
          bills.map((b) =>
            axios.put(`/api/transactions/${b.id}/match-status`, { match_status: status }),
          ),
        ),
      )
      .then(() => {
        refetch();
      });
  };

  const purchaseOrderUrl = `/purchase-orders/${purchaseOrderId}`;

  return (
    <ClosableDrawer title="PO Matching" open={open} onClose={onClose} width={1000}>
      {isLoading && (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </>
      )}
      {purchaseOrder && (
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box flexGrow={1}>
              <Typography sx={{ flexGrow: 1 }} variant="h6">
                {purchaseOrder.vendor.name} {purchaseOrder.increment_id}
              </Typography>
              <Box display="flex">
                <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                  {moment(purchaseOrder.issued_date).format('ll')}
                </Typography>
                <Typography variant="caption" color="primary">
                  {location.pathname === purchaseOrderUrl ? (
                    'You are viewing this PO'
                  ) : (
                    <a
                      href={purchaseOrderUrl}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Go To PO &rarr;
                    </a>
                  )}
                </Typography>
              </Box>
            </Box>

            <StatusChip
              sx={{ mx: 2, alignSelf: 'center' }}
              status={purchaseOrder.status}
              colors={PURCHASE_ORDER_STATUS_COLORS}
            />
          </AccordionSummary>
          <PurchaseOrderItems purchaseOrder={purchaseOrder} onUpdated={onUpdated} />
        </Accordion>
      )}
      {purchaseOrder && (
        <Grid container spacing={3} sx={{ my: 0 }}>
          <Grid size={{ xs: 12, md: 8 }}>
            {purchaseOrder.match_issues.length > 0 && (
              <Alert
                severity="warning"
                action={
                  <SubMenu
                    items={[
                      new ButtonAction('Attempt to Auto-Match', onAutoMatch),
                      new ButtonAction('Waiting on Invoice', () => onStatus('waiting_on_invoice')),
                      new ButtonAction('Waiting on Credit', () => onStatus('waiting_on_credit')),
                    ]}
                  />
                }
              >
                <AlertTitle>PO Match Issues</AlertTitle>
                <ul style={{ paddingLeft: 0 }}>
                  {purchaseOrder.match_issues.map((i) => (
                    <li key={i}>{i}</li>
                  ))}
                </ul>
              </Alert>
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <PurchaseOrderTotals purchaseOrder={purchaseOrder} onUpdated={onUpdated} />
          </Grid>
        </Grid>
      )}
      <Typography variant="overline" color="primary" component="div">
        Linked Bills
      </Typography>
      {bills.map((bill) => {
        const billUrl = `/transactions/${bill.id}`;

        return (
          <Accordion key={bill.id}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box flexGrow={1}>
                <Typography sx={{ flexGrow: 1 }} variant="h6">
                  {bill.label}
                </Typography>
                <Box display="flex">
                  <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                    {moment(bill.transaction_date).format('ll')}
                  </Typography>
                  <Typography variant="caption" color="primary">
                    {location.pathname === billUrl ? (
                      'You are viewing this bill'
                    ) : (
                      <a
                        href={billUrl}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Go To Bill &rarr;
                      </a>
                    )}
                  </Typography>
                </Box>
              </Box>

              <StatusChip
                sx={{ mx: 2, alignSelf: 'center' }}
                status={bill.match_status}
                colors={MATCH_STATUS_COLORS}
                labels={MATCH_STATUS_LABELS}
              />
            </AccordionSummary>

            {bill.type !== 'bill_payment' && (
              <BillItems items={bill.items as AccountTransactionItem[]} />
            )}
          </Accordion>
        );
      })}
    </ClosableDrawer>
  );
}
