import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  FormControl,
  FormLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import MuiCard from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import mapValues from 'lodash/mapValues';
import { useParams } from 'react-router-dom';
import SplashScreen from '@/SlashScreen';
import AvailLogo from '@/assets/logo.svg?react';
import { State, VendorRequest, VendorRequestPayload } from '@/types';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(3),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '600px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
  overflowY: 'auto',
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage: 'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
      backgroundImage: 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

export default function VendorRequestForm() {
  const { token } = useParams();

  const { data, isLoading, isError } = useQuery(
    ['vendorRequests', token],
    () =>
      axios
        .get<{
          data: VendorRequest;
          states: { [country: string]: State[] };
        }>(`/api/vendor-requests/${token}`)
        .then(({ data }) => data),
    {
      retry: false,
    },
  );

  const updateRequest = useMutation((payload: VendorRequestPayload) =>
    axios.put<VendorRequest>(`/api/vendor-requests/${token}`, payload).then(({ data }) => data),
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    updateRequest.mutate({
      account_number: data.get('accountNumber') as string,
      routing_number: data.get('routingNumber') as string,
      bank_type: data.get('bankType') as VendorRequestPayload['bank_type'],
      address: {
        name: 'Legal Address',
        address_1: data.get('address_1') as string,
        address_2: data.get('address_2') as string | null,
        city: data.get('city') as string,
        state: data.get('state') as string,
        zip: data.get('zip') as string,
        country: data.get('country') as string,
      },
    });
  };

  if (isLoading) {
    return <SplashScreen />;
  }

  if (isError) {
    return (
      <SignUpContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <AvailLogo style={{ height: 40, width: 'auto' }} />
          <Alert severity="error">
            We were unable anything at this URL. Perhaps the link expired or was already submitted.
          </Alert>
        </Card>
      </SignUpContainer>
    );
  }

  if (updateRequest.isSuccess) {
    return (
      <SignUpContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <AvailLogo style={{ height: 40, width: 'auto' }} />
          <Alert severity="success">
            Thank you! Your information has been submitted and payment is on the way.
          </Alert>
        </Card>
      </SignUpContainer>
    );
  }

  let errors: Record<string, string> = {};
  if (updateRequest.error instanceof AxiosError) {
    if (updateRequest.error.response?.data.errors) {
      errors = mapValues(updateRequest.error.response.data.errors, (e) => e[0]);
    }
  }

  return (
    <SignUpContainer direction="column" justifyContent="space-between">
      <Card variant="outlined">
        <AvailLogo style={{ minHeight: 40, width: 'auto' }} />
        <Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
          Avail wants to pay you!
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
        >
          <TextField
            label="Routing Number"
            required
            fullWidth
            id="routingNumber"
            name="routingNumber"
            variant="outlined"
            error={Boolean(errors.routing_number)}
            helperText={errors.routing_number}
          />

          <TextField
            label="Account Number"
            required
            fullWidth
            id="accountNumber"
            name="accountNumber"
            variant="outlined"
            error={Boolean(errors.account_number)}
            helperText={errors.account_number}
          />
          <FormControl error={Boolean(errors.bank_type)}>
            <InputLabel htmlFor="bankType">Account Type</InputLabel>
            <Select
              label="Account Type"
              required
              fullWidth
              name="bankType"
              id="bankType"
              variant="outlined"
            >
              <MenuItem value="businessChecking">Business Checking</MenuItem>
              <MenuItem value="businessSavings">Business Savings</MenuItem>
              <MenuItem value="personalChecking">Personal Checking</MenuItem>
              <MenuItem value="personalSavings">Personal Savings</MenuItem>
            </Select>
          </FormControl>

          <FormLabel>Legal Address</FormLabel>
          <TextField
            label="Address 1"
            required
            fullWidth
            id="address_1"
            name="address_1"
            variant="outlined"
            error={Boolean(errors['address.address_1'])}
            helperText={errors['address.address_1']}
          />
          <TextField
            label="Address 2"
            fullWidth
            id="address_2"
            name="address_2"
            variant="outlined"
            error={Boolean(errors['address.address_2'])}
            helperText={errors['address.address_2']}
          />
          <Box display="flex" gap={2}>
            <TextField
              label="City"
              fullWidth
              id="city"
              name="city"
              variant="outlined"
              required
              error={Boolean(errors['address.city'])}
              helperText={errors['address.city']}
            />
            <FormControl fullWidth error={Boolean(errors['address.state'])}>
              <InputLabel htmlFor="state">State</InputLabel>
              <Select label="State" required fullWidth name="state" id="state" variant="outlined">
                <ListSubheader>United States</ListSubheader>
                {data.states.US.map((state) => (
                  <MenuItem key={state.code} value={state.code}>
                    {state.name}
                  </MenuItem>
                ))}
                <ListSubheader>Canada</ListSubheader>
                {data.states.CA.map((state) => (
                  <MenuItem key={state.code} value={state.code}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Zip"
              fullWidth
              id="zip"
              name="zip"
              variant="outlined"
              error={Boolean(errors['address.zip'])}
              helperText={errors['address.zip']}
            />
          </Box>
          <FormControl fullWidth error={Boolean(errors['address.country'])}>
            <InputLabel htmlFor="country">Country</InputLabel>
            <Select
              label="Country"
              required
              fullWidth
              name="country"
              id="country"
              variant="outlined"
              defaultValue="US"
            >
              <MenuItem value="US">United States</MenuItem>
              <MenuItem value="CA">Canada</MenuItem>
            </Select>
          </FormControl>

          <LoadingButton
            loading={updateRequest.isLoading}
            type="submit"
            fullWidth
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </SignUpContainer>
  );
}
