import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  IconButton,
  styled,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import FormField from '@/components/Form/FormField';
import InventoryText from '@/components/Products/InventoryText';
import { orderItemWizardRowSchema } from '@/local-types';
import { SkuResource } from '@/types';

const ItemsBody = styled(TableBody)({
  '& tr:not(:last-child) td': {
    borderBottom: 'none',
    paddingBottom: 0,
  },
  '& tr:last-child td': {
    paddingTop: 2,
  },
  '& td': {
    padding: '6px 12px 6px 0',
    verticalAlign: 'middle',
  },
  '& td:first-child': {
    textAlign: 'center',
  },
});

type OrderItemPayload = z.infer<typeof orderItemWizardRowSchema>;

export default function ItemsSection({ isCustom }: { isCustom: boolean }) {
  const form = useFormContext<{
    items: Pick<OrderItemPayload, 'size' | 'qty' | 'price' | 'cost' | 'variant'>[];
  }>();
  const { append, fields, remove } = useFieldArray({
    name: 'items',
    control: form.control,
  });

  return (
    <>
      {fields.map((field, i) => {
        const parentName = `items.${i}`;
        return (
          <ItemsBody key={field.id}>
            <TableRow>
              <TableCell>
                <FormField
                  field={FieldFactory.text('size').withoutLabel().setDisabled(!isCustom)}
                  parentName={parentName}
                />
              </TableCell>
              <TableCell>
                <FormField
                  field={FieldFactory.number('qty').withoutLabel()}
                  parentName={parentName}
                />
              </TableCell>
              <TableCell>
                <FormField
                  field={FieldFactory.curr('price').withoutLabel().with({ maxDecimals: 4 })}
                  parentName={parentName}
                />
              </TableCell>
              <TableCell>
                <FormField
                  field={FieldFactory.curr('cost').withoutLabel().with({ maxDecimals: 4 })}
                  parentName={parentName}
                />
              </TableCell>
              {isCustom ? (
                <TableCell style={{ width: 80 }}>
                  <IconButton onClick={() => remove(i)} size="small">
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              ) : (
                <TableCell />
              )}
            </TableRow>
            {!isCustom && (
              <Controller
                name={`items.${i}.variant`}
                control={form.control}
                render={({ field }) =>
                  field.value ? (
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={4}>
                        <InventoryText variant={field.value as SkuResource} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )
                }
              />
            )}
          </ItemsBody>
        );
      })}

      {isCustom && (
        <TableRow>
          <TableCell colSpan={4} style={{ borderBottom: 0 }}>
            <Button
              onClick={() =>
                append({
                  size: 'OS',
                  qty: 0,
                  price: 0,
                  cost: null,
                })
              }
            >
              Add Size
            </Button>
          </TableCell>
        </TableRow>
      )}
      {!isCustom && fields.length === 0 && (
        <TableRow>
          <TableCell colSpan={4} style={{ borderBottom: 0 }}>
            <Typography variant="body2" color="textSecondary">
              Choose a product to get started
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
