import { ReactNode, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Fade, Typography } from '@mui/material';
import axios from 'axios';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FieldFactory } from '@/classes';
import FormField from '@/components/Form/FormField';
import Paper from '@/components/Shared/Paper';
import { ContactWithCustomer } from '@/local-types';
import { OrderCreateApiPayload } from '@/types';

function Section({
  title,
  subtitle,
  show,
  children,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
  show: boolean;
  children: ReactNode;
}) {
  return (
    <Fade in={show}>
      <div
        style={{
          marginRight: 42,
          marginBottom: 24,
          width: 450,
          verticalAlign: 'top',
          display: 'inline-block',
        }}
      >
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h6">{title}</Typography>
          {subtitle && (
            <Typography variant="body2" color="textSecondary">
              {subtitle}
            </Typography>
          )}
        </div>
        {show && children}
      </div>
    </Fade>
  );
}

export default function OrderCreateWizard({ isQuote = false }: { isQuote?: boolean }) {
  const form = useFormContext<OrderCreateApiPayload & { contact?: ContactWithCustomer | null }>();
  const { isSubmitting } = form.formState;
  const values = form.watch();

  const [searchParams] = useSearchParams();
  const customerId = searchParams.get('customer_id');
  const [who, setWho] = useState('customer');

  useEffect(() => {
    if (values.contact) {
      form.setValue('customer', values.contact.customer);
      if (values.contact.department) {
        form.setValue('department', values.contact.department);
      }
    }
  }, [values.contact?.id]);

  useEffect(() => {
    if (customerId) {
      axios.get(`/api/customers/${customerId}?with=customer_segment`).then(({ data }) => {
        form.setValue('customer', data);
      });
    }
  }, [customerId]);

  return (
    <Paper>
      <Section title="Order Type" subtitle="What kind of order is this?" show>
        <FormField
          field={FieldFactory.belongsTo('order_type', 'orderTypes')
            .withoutLabel()
            .withRequestParams({ count: 100 })}
        />
      </Section>

      <Section
        title="Customer"
        subtitle={
          <div>
            Which <span style={{ fontWeight: 500 }}>{who}</span> is this for?
          </div>
        }
        show
      >
        {who === 'customer' && (
          <div>
            <FormField
              field={FieldFactory.belongsTo('customer', 'customers')
                .withoutLabel()
                .with({
                  getSubtitle: (v) => v.customer_segment?.name,
                  placeholder: 'Search By Customer',
                  requestParams: {
                    with: 'customer_segment',
                    is_disabled: 0,
                  },
                })}
            />
            <Button type="button" onClick={() => setWho('contact')} size="small">
              Search By Contact
            </Button>
          </div>
        )}

        {who === 'contact' && (
          <div>
            <FormField
              field={FieldFactory.belongsTo('contact', 'contacts').with({
                placeholder: 'Search By Contact',
                requestParams: { with: 'customer,customer.customer_segment,department.set' },
              })}
            />
            <Button type="button" onClick={() => setWho('customer')} size="small">
              Search By Customer
            </Button>
          </div>
        )}
      </Section>

      <Section
        title="Department"
        subtitle="Which department within the customer is this for?"
        show={Boolean(values.order_type && values.customer)}
      >
        <FormField
          field={FieldFactory.belongsTo('department', 'departments').with({
            requestParams: {
              customer: values.customer?.id,
              with: 'set',
            },
          })}
        />
      </Section>

      <Section
        title="Nickname"
        subtitle="Provide a name so you can refer to it later"
        show={Boolean(values.order_type && values.customer && values.department)}
      >
        <div style={{ marginTop: -8 }}>
          <FormField
            field={FieldFactory.text('description')
              .withSize('medium')
              .withPlaceholder('e.g. Acme Baseball Hoodies')}
          />
        </div>
      </Section>

      {!isQuote && (
        <Section
          title="Deadline"
          subtitle="Does the customer need this order by a certain date? Rush fees may apply."
          show={Boolean(values.order_type && values.customer && values.department)}
        >
          <div style={{ marginTop: -8 }}>
            <FormField field={FieldFactory.date('drop_dead_date').withSize('medium')} />
          </div>
        </Section>
      )}

      <Fade in={Boolean(values.order_type && values.customer && values.description)}>
        <div>
          <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
            Create {isQuote ? 'Quote' : 'Order'}
          </LoadingButton>
        </div>
      </Fade>
    </Paper>
  );
}

export function QuoteCreateWizard() {
  return <OrderCreateWizard isQuote />;
}
