import { Box, Typography } from '@mui/material';
import { green, orange } from '@mui/material/colors';
import RateOfChangePill from '@/components/Reports/RateOfChangePill';
import StatCardContent from '@/components/Stats/StatCardContent';
import StatCardNumber from '@/components/Stats/StatCardNumber';
import { ReportTypeProps } from '@/local-types';
import { ReportResultsRow } from '@/types';
import { StatFormat } from '@/utils/formatNumber';

const budgetFooter = (budget: number, current: number, format: StatFormat = 'curr') => {
  if (current < budget) {
    return (
      <div>
        <b style={{ color: orange[500] }}>
          <StatCardNumber number={budget - current} format={format} />
        </b>
        <span> to reach goal of </span>
        <b>
          <StatCardNumber number={budget} format={format} />
        </b>
      </div>
    );
  }
  return (
    <div>
      <b style={{ color: green[500] }}>
        <StatCardNumber number={current - budget} format={format} />
      </b>
      <span> above goal of </span>
      <b>
        <StatCardNumber number={budget} format={format} />
      </b>
    </div>
  );
};

function getNumberFromRows(results: ReportResultsRow[]): number {
  const row = results[0] || {};
  return Number(Object.values(row)[0]);
}

function getBudgetFromRows(results: ReportResultsRow[]) {
  const row = results[0] || {};
  return row.budget;
}

export default function ReportNumber({
  results,
  columns,
  compareTo,
  compareToResults,
}: ReportTypeProps) {
  const number = getNumberFromRows(results);
  const budget = getBudgetFromRows(results);
  const format = (Object.values(columns || {})[0]?.format || 'number') as StatFormat;

  const getFooter = () => {
    if (budget) {
      return budgetFooter(Number(budget), number, format);
    }
    if (compareTo && compareToResults) {
      const compareToNumber = getNumberFromRows(compareToResults);
      if (compareToNumber) {
        return (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <RateOfChangePill
              number={number}
              compareToType={compareTo}
              compareTo={getNumberFromRows(compareToResults)}
              format={format}
            />
          </Box>
        );
      }
    }
    return null;
  };

  const footer = getFooter();

  return (
    <StatCardContent number={number} format={format || 'number'}>
      {footer && <Typography variant="body2">{footer}</Typography>}
    </StatCardContent>
  );
}
