import { ComponentType } from 'react';
import { CardContent, Typography } from '@mui/material';
import size from 'lodash/size';
import { ReportTypeProps } from '@/local-types';
import { CompareTo, Report, ReportResultsRow } from '@/types';
import ReportGraph from './Types/ReportGraph';
import ReportList from './Types/ReportList';
import ReportNumber from './Types/ReportNumber';
import ReportProgress from './Types/ReportProgress';
import ReportTable from './Types/ReportTable';

export default function ReportResults({
  report,
  results,
  compareTo,
  compareToResults,
}: {
  report: Report;
  results: ReportResultsRow[];
  compareTo?: CompareTo;
  compareToResults?: ReportResultsRow[];
}) {
  const propsToPass: ReportTypeProps = {
    results,
    compareTo,
    compareToResults,
    summary: report.summary,
    type: report.type,
    columns: report.columns,
  };

  if (size(results) === 0) {
    return (
      <CardContent>
        <Typography color="textSecondary" variant="body2">
          No results found
        </Typography>
      </CardContent>
    );
  }

  const Component = (function getComponent(): ComponentType<ReportTypeProps> | null {
    switch (report.type) {
      case 'table':
        return ReportTable;
      case 'list':
        return ReportList;
      case 'number':
        return ReportNumber;
      case 'bar':
      case 'line':
      case 'area':
        return ReportGraph;
      case 'progress':
        return ReportProgress;
      default:
        return null;
    }
  })();

  if (Component) {
    return <Component {...propsToPass} />;
  }

  return <CardContent>{JSON.stringify(results)}</CardContent>;
}
